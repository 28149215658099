import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './manager-client-actions';

export const GET_ASSESSMENT_QUESTIONS = 'GET_ASSESSMENT_QUESTIONS';
export const REMOVE_ASMT_QUESTION = 'REMOVE_ASMT_QUESTION';
export const EDIT_ASMT_QUESTION = 'EDIT_ASMT_QUESTION';
export const ADD_ASMT_QUESTION = 'ADD_ASMT_QUESTION';
export const BULK_ASSIGN = 'BULK_ASSIGN';
export const BULK_UNASSIGN = 'BULK_UNASSIGN';
export const GET_ASSIGNMENT_SUMMARY = 'GET_ASSIGNMENT_SUMMARY'
export const GET_QUESTION_BANK = 'GET_QUESTION_BANK'

export function getAssessmentQuestions(assessment_id) {
	return dispatch => {
		dispatch(requestClient(GET_ASSESSMENT_QUESTIONS));
		return client.service('manager-question-data').find({
			query: { assessment_id }
		})
		.then(response => {
			dispatch(successClient(GET_ASSESSMENT_QUESTIONS, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASSESSMENT_QUESTIONS, error));
			console.log("Error: ", error);
			return null;
		})
	}
}

export function removeAsmtQuestion(id) {
	return dispatch => {
		dispatch(requestClient(REMOVE_ASMT_QUESTION));
		return client.service('assessment-questions').remove(id) // single remove
		.then(response => {
			dispatch(successClient(REMOVE_ASMT_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(REMOVE_ASMT_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function editAsmtQuestion(id, edited_question, assessment_subcategory_id) {
	return dispatch => {
		dispatch(requestClient(EDIT_ASMT_QUESTION));
		return client.service('assessment-questions').patch(id, edited_question)
		.then(response => {
			dispatch(successClient(EDIT_ASMT_QUESTION, response));
			// dispatch(getQuestionsBySubcategory(assessment_subcategory_id)); // TODO
		}).catch(error => {
			dispatch(failureClient(EDIT_ASMT_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function addAsmtQuestion(new_question) {
	return dispatch => {
		dispatch(requestClient(ADD_ASMT_QUESTION));
		return client.service('assessment-questions').create(new_question)
		.then(response => {
			dispatch(successClient(ADD_ASMT_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(ADD_ASMT_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

// bulkAssign/bulkUnassign params structure is: {user_id, domain_id, assessment_id, assessment_function_id, assessment_category_id, assessment_subcategory_id, assessment_question_id}
// - user_id and assessment_id is required
export function bulkAssign(params_array, assessment_id) {
	return dispatch => {
		dispatch(requestClient(BULK_ASSIGN));
		return client.service('noxmon-bulk-assignment').create(params_array)
		.then(response => {
			dispatch(getAssignmentSummary(assessment_id));
			dispatch(successClient(BULK_ASSIGN, response));
		}).catch(error => {
			dispatch(failureClient(BULK_ASSIGN, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function bulkUnassign(params_array, assessment_id) {
	return dispatch => {
		dispatch(requestClient(BULK_UNASSIGN));
		return client.service('noxmon-bulk-assignment').remove(null, { query: params_array })
		.then(response => {
			dispatch(getAssignmentSummary(assessment_id));
			dispatch(successClient(BULK_UNASSIGN, response));
		}).catch(error => {
			dispatch(failureClient(BULK_UNASSIGN, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getAssignmentSummary(assessment_id) {
	return dispatch => {
		dispatch(requestClient(GET_ASSIGNMENT_SUMMARY));
		return client.service('noxmon-bulk-assignment').get(assessment_id)
		.then(response => {
			dispatch(successClient(GET_ASSIGNMENT_SUMMARY, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASSIGNMENT_SUMMARY, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getQuestionBank(page = 0, searchString) {
	return dispatch => {
		dispatch(requestClient(GET_QUESTION_BANK));
		const query = {
			$skip: page * 10
		}
		if (searchString && searchString !== '') {
			query.description = {
				$iLike: `%${searchString}%`
			}
		}
		return client.service('question-bank').find({ query })
		.then(response => {
			dispatch(successClient(GET_QUESTION_BANK, response));
		}).catch(error => {
			dispatch(failureClient(GET_QUESTION_BANK, error));
			console.log("Error: ", error);
			return null;
		});
	}
}