import React from 'react';

const AnalystDashboard = React.lazy(() => import('../views/Dashboard/AnalystDashboard'));
const AnalystAssessmentDashboard = React.lazy(() => import('../views/Analyst/Dashboard/AnalystAssessmentDashboard'));
const AnalystInherentState = React.lazy(() => import('../views/Analyst/InherentState/InherentState'));

const analyst_routes = [
  { path: '/', exact: true, name: 'Home'},
  { path: '/analyst/dashboard/', exact: true, name: 'Dashboard', component: AnalystDashboard },
  { path: '/analyst/assessment/:id', exact: true, name: 'Assessment Dashboard', component: AnalystAssessmentDashboard },
  { path: '/analyst/assessment/:id/dashboard', exact: true, name: 'Assessment Dashboard', component: AnalystAssessmentDashboard },
  { path: '/analyst/assessment/:id/subcategory/:assessment_category_id', exact: true, name: 'Inherent State', component: AnalystInherentState }
];

export default analyst_routes;
