import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { AppNavbarBrand } from '@coreui/react';

import logo_icon from '../../assets/img/brand/logo_icon.png'
import logo2 from '../../assets/img/brand/1x/logo2.png'

class HeaderNavBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to="/"/>);
    }

    return (
      <Fragment>
        <AppNavbarBrand
          full={{ src: logo2, height: 50, alt: 'Noxmon Logo' }}
          onClick={() => this.setState({redirect: true})}
          minimized={{ src: logo_icon, width: 30, height: 30, alt: 'Noxmon Logo' }}
        />
      </Fragment>
    );
  }
}

export default HeaderNavBrand;
