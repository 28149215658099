import { defaultProps, setFetching, setData, setError } from '../util';

import {
	ANALYST_CLIENT_REQUEST,
	ANALYST_CLIENT_SUCCESS,
	ANALYST_CLIENT_FAILURE,
} from '../../actions/analyst/analyst-client-actions';

import {
	GET_QUESTION_CATEGORY,
	PATCH_ANALYST_QUESTION,
	CREATE_QM,
	UPDATE_QM,
	DELETE_QM,
	UPLOAD_QM_DOCUMENT,
	GET_QM_DOCUMENT,
	DELETE_QM_DOCUMENT
} from '../../actions/analyst/question-actions';

const defaultState = {
	question_category: defaultProps,
	patch_analyst_question: defaultProps
}

function handlePatchAnalystQuestion(state, updated) {
	const { generic_questions } = state.question_category.data
	let assessment_question_id
	if (Array.isArray(updated)) { // when the risk level is patched, all user questions are updated
		assessment_question_id = updated[0].assessment_question_id
	} else {
		assessment_question_id = updated.assessment_question_id
	}
	const new_generic_questions = generic_questions.map(question => {
		if (question.assessment_question_id === assessment_question_id) {
			let new_assignments
			if (Array.isArray(updated)) {
				new_assignments = updated
			} else {
				new_assignments = question.assignments.map(user_question => {
					if (user_question.id === updated.id) {
						return updated
					}
					return user_question
				})
			}
			return {
				...question,
				assignments: new_assignments
			}
		}
		return question
	})
	return {
		...state,
		question_category: {
			...state.question_category,
			data: {
				...state.question_category.data,
				generic_questions: new_generic_questions
			}
		},
		patch_analyst_question: defaultProps
	}
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		case ANALYST_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_QUESTION_CATEGORY: {
					return setFetching('question_category', state);
				}
				case PATCH_ANALYST_QUESTION: {
					return setFetching('patch_analyst_question', state);
				}
				default: 
					return state;
			}
		}
		case ANALYST_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_QUESTION_CATEGORY: {
					return setData('question_category', state, action.data);
				}
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
				case PATCH_ANALYST_QUESTION: {
					return handlePatchAnalystQuestion(state, action.data)
				}
				default:
					return state;
			}
		}
		case ANALYST_CLIENT_FAILURE: {
			switch (action.name) {
				case GET_QUESTION_CATEGORY: {
					return setError('question_category', state, action.error);
				}
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
				case PATCH_ANALYST_QUESTION: {
					return setError('patch_analyst_question', state, action.error);
				}
				default: 
					return state;
			}
		}
		default:
			return state;
	}
}
