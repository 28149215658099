import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth0Login from './views/Pages/Auth0Login';
import Loadable from 'react-loadable';
import './App.scss';
import { IsAuth } from './utils/isAuth';
import analyst_routes from './routes/analyst-routes';
import manager_routes from './routes/manager-routes';
import admin_routes from './routes/admin-routes';
import documentation_routes from './routes/documentation-routes';
import LayoutNoSidebar from './containers/Layout/LayoutNoSidebar';
import SidebarLayout from './containers/SidebarLayout/SidebarLayout';

import ErrorSnackbar from './utils/ErrorSnackbar'
import HeaderNoSidebar from './containers/Layout/HeaderNoSidebar';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const Layout = Loadable({
  loader: () => import('./containers/Layout'),
  loading
});

// Pages
const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {
  componentDidMount () {
    // Add Crisp chat
    window.$crisp = [];
    window.CRISP_WEBSITE_ID="9c56c588-61e5-4b2f-bc2c-ba1132b81a76";

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Auth0Login} />
          
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />

          <IsAuth>
            <Switch>
              {/* <Route exact path="/register" name="Register Page" component={Register} /> */}
              <Route path="/documentation" name="Info" render={props => <LayoutNoSidebar {...props} routes={documentation_routes}/>} />
              <Route exact path="/" name="Home" component={Layout} />
              <Route path="/dashboard" name="Home" component={Layout} />
              <Route path="/admin" name="Admin" render={props => <LayoutNoSidebar {...props} routes={admin_routes}/>} />
              <Route path="/manager/assessment" name="Assessment" render={props => <SidebarLayout {...props} routes={manager_routes}/>} />
              <Route path="/manager" name="Home" render={props => <LayoutNoSidebar {...props} routes={manager_routes}/>} />
              <Route path="/analyst/assessment" name="Assessment" render={props => <SidebarLayout {...props} routes={analyst_routes}/>} />
              <Route path="/analyst" name="Home" render={props => <LayoutNoSidebar {...props} routes={analyst_routes}/>} />
            </Switch>
          </IsAuth>
        </Switch>
        <ErrorSnackbar 
          errorName="timeoutError"
          message="The server has timed out. Please try again."
        />
        <ErrorSnackbar 
          errorName="serverError"
          message="The server is not responding properly. Please try again or contact the administrator."
        />
        <ErrorSnackbar 
          errorName="genericError"
          message="Something has gone wrong. Please try again or contact the administrator."
          dispatch={() => {}}
        />
        <ErrorSnackbar 
          errorName="loginError"
          message="Something has gone wrong. Please try logging out and logging in again, or contact the administrator."
        />
      </div>
    );
  }
}


export default App;
