import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './common-client-actions';

export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_STRUCTURE = 'GET_ASSESSMENT_STRUCTURE';
export const GET_ASSESSMENT_HISTORY = 'GET_ASSESSMENT_HISTORY';
export const GET_ASSESSMENT_SCORING = 'GET_ASSESSMENT_SCORING';

export const RESET_ASSESSMENT_STRUCTURE = 'RESET_ASSESSMENT_STRUCTURE';
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export const RESET_ERROR = 'RESET_ERROR';

export const CREATE_QM = 'CREATE_QM';
export const UPDATE_QM = 'UPDATE_QM';
export const DELETE_QM = 'DELETE_QM';

export const UPLOAD_QM_DOCUMENT = 'UPLOAD_QM_DOCUMENT';
export const GET_QM_DOCUMENT = 'GET_QM_DOCUMENT';
export const DELETE_QM_DOCUMENT = 'DELETE_QM_DOCUMENT';

export function resetAssessment(){	
	return {
		type: RESET_ASSESSMENT
	}
}

export function resetAssessmentStructure(){	
	return {
		type: RESET_ASSESSMENT_STRUCTURE
	}
}

export function resetErrors(key){	
	return {
		type: RESET_ERROR,
		key
	}
}

export function getAssessments() {
	return dispatch => {
		dispatch(requestClient(GET_ASSESSMENTS));
		return client.service('noxmon-assessment').find()
		.then(response => {
			dispatch(successClient(GET_ASSESSMENTS, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASSESSMENTS, error));
			return null;
		});
	}
}

export function getAssessment(id) {
	return dispatch => {
		dispatch(resetAssessmentStructure());
		dispatch(resetAssessment());
		if (id) {
			dispatch(requestClient(GET_ASSESSMENT));
			dispatch(getAssessmentStructure(id))
			dispatch(getAssessmentHistory(id))
			dispatch(getAssessmentScoring(id))
			return client.service('noxmon-assessment').get(id)
			.then(response => {
				dispatch(successClient(GET_ASSESSMENT, response));
			}).catch(error => {
				dispatch(failureClient(GET_ASSESSMENT, error));
				console.log("Error: ", error);
				return null;
			});
		} else {
			console.log("Error getting assessment: no id provided")
			return null;
		}
	}
}

export function getAssessmentStructure(assessment_id) {
	return dispatch => {
		dispatch(requestClient(GET_ASSESSMENT_STRUCTURE));
		return client.service('noxmon-assessment-structure').get(assessment_id)
		.then(response => {
			dispatch(successClient(GET_ASSESSMENT_STRUCTURE, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASSESSMENT_STRUCTURE, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function getAssessmentHistory(id) {
	return dispatch => {
		if (id) {
			dispatch(requestClient(GET_ASSESSMENT_HISTORY));
			return client.service('noxmon-assessment-history').find({ query: { assessment_id: id } })
			.then(response => {
				dispatch(successClient(GET_ASSESSMENT_HISTORY, response));
			}).catch(error => {
				dispatch(failureClient(GET_ASSESSMENT_HISTORY, error));
				console.log("Error: ", error);
				return null;
			});
		} else {
			console.log("Error getting assessment history: no id provided")
			return null;
		}
	}
}

export function getAssessmentScoring(id) {
	return dispatch => {
		dispatch(requestClient(GET_ASSESSMENT_SCORING));
		return client.service('noxmon-scoring').get(id)
		.then(response => {
			dispatch(successClient(GET_ASSESSMENT_SCORING, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASSESSMENT_SCORING, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createQuestionMethod(data) {
	const { document, ...method_data } = data
	return dispatch => {
		dispatch(requestClient(CREATE_QM));
		return client.service('question-methods').create(method_data)
		.then(response => {
			dispatch(successClient(CREATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(response.id, document))
			}
		}).catch(error => {
			dispatch(failureClient(CREATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function updateQuestionMethod(id, updated) {
	const { document, ...method_data } = updated
	return dispatch => {
		dispatch(requestClient(UPDATE_QM));
		return client.service('question-methods').patch(id, method_data)
		.then(response => {
			dispatch(successClient(UPDATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(response.id, document))
			}
		}).catch(error => {
			dispatch(failureClient(UPDATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethod(id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM));
		return client.service('question-methods').remove(id)
		.then(response => {
			dispatch(successClient(DELETE_QM, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function uploadQuestionMethodDocument(question_method_id, file) {
	return dispatch => {
		dispatch(requestClient(UPLOAD_QM_DOCUMENT));
		const fData = new FormData();
		fData.append('filename', 'x');
		fData.append('file', file);
		fData.append('question_method_id', question_method_id);
		return client.service('noxmon-question-method-documents').create(fData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			dispatch(successClient(UPLOAD_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(UPLOAD_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getQuestionMethodDocument(question_method_id) {
	return dispatch => {
		dispatch(requestClient(GET_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').get(question_method_id)
		.then(response => {
			console.log(response)
			dispatch(successClient(GET_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(GET_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethodDocument(document_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').remove(document_id)
		.then(response => {
			console.log(response)
			dispatch(successClient(DELETE_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}