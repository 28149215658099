import { defaultProps, setFetching, setData, setError } from '../util';
import { merge } from 'lodash';

import {
	MANAGER_CLIENT_REQUEST,
	MANAGER_CLIENT_SUCCESS,
	MANAGER_CLIENT_FAILURE,
} from '../../actions/manager/manager-client-actions';

import {
	GET_QUESTION_CATEGORY,
	MANAGER_PATCH_QUESTION,
	ASSIGN_QUESTION,
	UNASSIGN_QUESTION,
	GET_COMMENTS,
	POST_COMMENT,
	DELETE_COMMENT,
	CREATE_QM,
	UPDATE_QM,
	DELETE_QM,
	UPLOAD_QM_DOCUMENT,
	GET_QM_DOCUMENT,
	DELETE_QM_DOCUMENT
} from '../../actions/manager/subcategory-actions';

const defaultState = {
	question_category: defaultProps,
	patching_question: null,
	patching_question_error: null,
	updating_question_assignment: false,
	question_assignment_error: null,
	currentSubcategory: null,
	comment_fetching: false,

	comments: [],
	error: null
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		case MANAGER_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_QUESTION_CATEGORY: {
					return setFetching('question_category', state);
				}
				case MANAGER_PATCH_QUESTION: {
					return {
						...state,
						patching_question: action.param
					}
				}
				case ASSIGN_QUESTION || UNASSIGN_QUESTION: {
					return {
						...state,
						updating_question_assignment: true
					}
				}
				case GET_COMMENTS || POST_COMMENT || DELETE_COMMENT: {
					return {
						...state,
						comment_fetching: true
					}
				}
				default:
					return state;
			}
		}

		// SUCCESSES //
		case MANAGER_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_COMMENTS: {
					return {
						...state,
						comment_fetching: false,
						comments: action.data
					}
				}
				// TODO: update comment array here
				case POST_COMMENT: {
					return {
						...state,
						comment_fetching: false,
						comments: [action.data, ...state.comments]
					}
				}
				case DELETE_COMMENT: {
					return {
						...state,
						comment_fetching: false,
						comments: state.comments.filter((comment) => comment.id !== action.data.id)
					}
				}
				case GET_QUESTION_CATEGORY: {
					return setData('question_category', state, action.data);
				}
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
				case MANAGER_PATCH_QUESTION: {
					return {
						...state,
						question_category: updateQuestionAssignment(state.question_category, action.data, action.name),
						patching_question: null
					}
				}
				case ASSIGN_QUESTION: {
					return {
						...state,
						question_category: updateQuestionAssignment(state.question_category, action.data, action.name),
						updating_question_assignment: false
					}
				}
				case UNASSIGN_QUESTION: {
					return {
						...state,
						question_category: updateQuestionAssignment(state.question_category, action.data, action.name),
						updating_question_assignment: false
					}
				}
				default: 
					return state;
			}
		}

		// FAILURES //
		case MANAGER_CLIENT_FAILURE: {
			switch (action.name) {
				case GET_COMMENTS || POST_COMMENT || DELETE_COMMENT: {
					return {
						...state,
						comment_fetching: false,
						error: action.error
					}
				}
				case GET_QUESTION_CATEGORY: {
					return setError('question_category', state, action.error);
				}
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
				case MANAGER_PATCH_QUESTION: {
					return {
						...state,
						patching_question: null,
						patching_question_error: action.error
					}
				}
				case ASSIGN_QUESTION || UNASSIGN_QUESTION: {
					return {
						...state,
						updating_question_assignment: false,
						question_assignment_error: action.error
					}
				}
				default: 
					return state;
			}
		}

		default:
			return state;
	}
}

function updateQuestionAssignment(question_category, result, action) {
	let result_asmt_question_id = result.assessment_question_id
	if (!result_asmt_question_id && result.length > 0) {
		result_asmt_question_id = result[0].assessment_question_id
	}
	const new_generic_questions = question_category.data.generic_questions.map(question => {
		if (question.assessment_question_id === result_asmt_question_id) {
			let new_assignments
			if (action === ASSIGN_QUESTION) {
				new_assignments = [...question.assignments, result]
			} else if (action === UNASSIGN_QUESTION) {
				new_assignments = question.assignments.filter(assignment => assignment.id !== result.id)
			} else {
				if (Array.isArray(result)) { // array returned if risk factor patched
					new_assignments = question.assignments.map(old_assignment => {
						const match = result.find(r => r.id === old_assignment.id)
						return match ? merge(old_assignment, match) : old_assignment
					})
				} else {
					new_assignments = question.assignments.map(old_assignment => {
						if (old_assignment.id === result.id) {
							if (action === DELETE_QM || action === DELETE_QM_DOCUMENT) {
								return result
							} else {
								return merge(old_assignment, result)
							}
						} else {
							return old_assignment
						}
					})
				}
			}
			return {
				...question,
				assignments: new_assignments
			}
		} else {
			return question
		}
	})
	return {
		...question_category,
		data: {
			...question_category.data,
			generic_questions: new_generic_questions
		}
	}
}