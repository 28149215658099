import { COMMON_CLIENT_REQUEST, COMMON_CLIENT_SUCCESS, COMMON_CLIENT_FAILURE } from '../actions/common/common-client-actions'
import { defaultProps, setFetching, setData, setError } from './util'
import { 
	GET_ASSESSMENTS,
	GET_ASSESSMENT,
	GET_ASSESSMENT_STRUCTURE,
	GET_ASSESSMENT_HISTORY,
	GET_ASSESSMENT_SCORING,
	RESET_ASSESSMENT_STRUCTURE,
	RESET_ASSESSMENT,
	RESET_ERROR
} from '../actions/common/assessment-actions'
import {
	CREATE_GENERIC_CATEGORY,
	PATCH_GENERIC_CATEGORY,
	DELETE_GENERIC_CATEGORY
} from '../actions/manager/assessment-structure-actions'
import {
	GET_USER_QUESTION_CATEGORY,
	CREATE_QM,
	UPDATE_QM,
	DELETE_QM,
	UPLOAD_QM_DOCUMENT,
	GET_QM_DOCUMENT,
	DELETE_QM_DOCUMENT
} from '../actions/common/question-actions';
import {
	GET_USERS,
	CREATE_USER,
	DELETE_USER,
	PATCH_USER,
	RESEND_EMAIL,
	RESET_USER_EMAIL_STATUS
} from '../actions/common/user-actions';
import { merge } from 'lodash';

const defaultState = {
	assessments: defaultProps,
	assessment: defaultProps,
	assessment_structure: defaultProps,
	assessment_history: defaultProps,
	assessment_scoring: defaultProps,
	user_question_category_questions: defaultProps,
	users: defaultProps,

	// no data
	create_user: defaultProps,
	delete_user: defaultProps,
	patch_user: defaultProps,

	fetching_structure_update: false,
	structure_update_error: null,

	fetching_resend_email: false,
	resend_email_success: false,
	resend_email_error: false,
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		// internal state managerment functions
		case RESET_ASSESSMENT_STRUCTURE: {
			return { ...state, assessment_structure: defaultProps }
		}
		case RESET_ASSESSMENT: {
			return { ...state, assessment: defaultProps }
		}
		case RESET_ERROR: {
			console.log(action) // TODO
		}
		case RESET_USER_EMAIL_STATUS: {
			return {
				...state,
				fetching_resend_email: false,
				resend_email_success: false,
				resend_email_error: false
			}
		}

		// client functions
		case COMMON_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_ASSESSMENTS:
					return setFetching('assessments', state)
				case GET_ASSESSMENT:
					return setFetching('assessment', state)
				case GET_ASSESSMENT_STRUCTURE:
					return setFetching('assessment_structure', state)
				case GET_ASSESSMENT_HISTORY:
					return setFetching('assessment_history', state)
				case GET_ASSESSMENT_SCORING:
					return setFetching('assessment_scoring', state)
				case CREATE_GENERIC_CATEGORY:
				case PATCH_GENERIC_CATEGORY:
				case DELETE_GENERIC_CATEGORY:
					return {
						...state,
						fetching_structure_update: true,
						structure_update_error: null
					}
				case GET_USER_QUESTION_CATEGORY:
					return setFetching('user_question_category_questions', state)
				case GET_USERS:
					return setFetching('users', state)
				case CREATE_USER:
					return setFetching('create_user', state)
				case DELETE_USER: 
					return setFetching('delete_user', state)
				case PATCH_USER: 
					return setFetching('patch_user', state)
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: true,
						resend_email_success: false,
						resend_email_error: false
					}
				}
				default:
					return state
			}
		}

		case COMMON_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_ASSESSMENTS:
					return setData('assessments', state, action.data)
				case GET_ASSESSMENT:
					return setData('assessment', state, action.data)
				case GET_ASSESSMENT_STRUCTURE:
					return setData('assessment_structure', state, action.data)
				case GET_ASSESSMENT_HISTORY:
					return setData('assessment_history', state, action.data)
				case GET_ASSESSMENT_SCORING:
					return setData('assessment_scoring', state, action.data)
				case CREATE_GENERIC_CATEGORY:
					// return updateAssessmentStructure('create', state, action.data)
				case PATCH_GENERIC_CATEGORY:
					// return updateAssessmentStructure('patch', state, action.data)
				case DELETE_GENERIC_CATEGORY:
					// return updateAssessmentStructure('delete', state, action.data)
					return { ...state, fetching_structure_update: false }
				case GET_USER_QUESTION_CATEGORY:
					return setData('user_question_category_questions', state, action.data)
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
					return updateUserQuestionCategoryQuestions(state, action.data, action.name)
				case GET_USERS:
					return setData('users', state, action.data)
				case CREATE_USER:
					return setData('create_user', state, action.data)
				case DELETE_USER: 
					return setData('delete_user', state, action.data)
				case PATCH_USER: 
					return setData('patch_user', state, action.data)
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: false,
						resend_email_success: true
					}
				}
				default:
					return state
			}
		}

		case COMMON_CLIENT_FAILURE: {
			switch (action.name) {
				case GET_ASSESSMENTS:
					return setError('assessments', state, action.error)
				case GET_ASSESSMENT:
					return setError('assessment', state, action.error)
				case GET_ASSESSMENT_STRUCTURE:
					return setError('assessment_structure', state, action.error)
				case GET_ASSESSMENT_HISTORY:
					return setError('assessment_history', state, action.error)
				case GET_ASSESSMENT_SCORING:
					return setError('assessment_scoring', state, action.error)
				case CREATE_GENERIC_CATEGORY:
				case PATCH_GENERIC_CATEGORY:
				case DELETE_GENERIC_CATEGORY:
					return { 
						...state,
						fetching_structure_update: false,
						structure_update_error: action.error
					}
				case GET_USER_QUESTION_CATEGORY:
					return setError('user_question_category_questions', state, action.error)
				case CREATE_QM:
				case UPDATE_QM:
				case DELETE_QM:
				case UPLOAD_QM_DOCUMENT:
				case GET_QM_DOCUMENT:
				case DELETE_QM_DOCUMENT:
					return setError('user_question_category_questions', state, action.error)
				case GET_USERS:
					return setError('users', state, action.error)
				case CREATE_USER:
					return setError('create_user', state, action.error)
				case DELETE_USER: 
					return setError('delete_user', state, action.error)
				case PATCH_USER: 
					return setError('patch_user', state, action.error)
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: false,
						resend_email_error: true
					}
				}
				default:
					return state
			}
		}

		default:
			return state
	}
}

function updateUserQuestionCategoryQuestions(state, updated, action) {
	const { generic_questions } = state.user_question_category_questions.data
	let assessment_question_id
	if (Array.isArray(updated)) { // when the risk level is patched, all user questions are updated
		assessment_question_id = updated[0].assessment_question_id
	} else {
		assessment_question_id = updated.assessment_question_id
	}
	const new_generic_questions = generic_questions.map(question => {
		if (question.assessment_question_id === assessment_question_id) {
			let new_assignments
			if (Array.isArray(updated)) {
				new_assignments = question.assignments.map(user_question => {
					const match = updated.find(r => r.id === user_question.id)
					return match ? merge(user_question, match) : user_question
				})
			} else {
				new_assignments = question.assignments.map(user_question => {
					if (user_question.id === updated.id) {
						return merge(user_question, updated)
					}
					return user_question
				})
			}
			return {
				...question,
				assignments: new_assignments
			}
		}
		return question
	})
	return {
		...state,
		user_question_category_questions: {
			...state.user_question_category_questions,
			data: {
				...state.user_question_category_questions.data,
				generic_questions: new_generic_questions
			}
		}
	}
}