import React from 'react';

const DocumentationHome = React.lazy(() => import('../views/Pages/Documentation/DocumentationHome.js'));

const documentation_routes = [
  { path: '/', exact: true, name: 'Info', component: DocumentationHome},
  { path: '/documentation', exact: true, name: 'Info', component: DocumentationHome},
];

export default documentation_routes;
