import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './analyst-client-actions';

export const GET_ASMTS = 'GET_ASMTS';
export const GET_SCORE = 'GET_SCORE';

// Get all assessments assigned to an analyst
// TODO: This can be moved to common
// export function getAsmts(user_id) {
// 	return dispatch => {
// 		dispatch(requestClient(GET_ASMTS));
// 		return client.service('noxmon-assessment').find()
// 			.then(response => {
// 				dispatch(successClient(GET_ASMTS, response));
// 			}).catch(error => {
// 				dispatch(failureClient(GET_ASMTS, error));
// 				console.log("Error: ", error);
// 				return null;
// 			});
// 	}
// }
