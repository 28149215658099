import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './common-client-actions';

export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const PATCH_USER = 'PATCH_USER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESET_USER_EMAIL_STATUS = 'RESET_USER_EMAIL_STATUS';

export function getUsers() {
	return (dispatch) => {
		dispatch(requestClient(GET_USERS));
		return client.service('noxmon-user').find()
    .then(response => {
			dispatch(successClient(GET_USERS, response));
		}).catch(error => {
			dispatch(failureClient(GET_USERS, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createUser(credentials) {
	const { username, email, role, organization_id } = credentials;
	return dispatch => {
		dispatch(requestClient(CREATE_USER));
		return client.service('noxmon-user')
			.create({ 
				username,
        email,
        role,
        organization_id
			}).then(response => {
				dispatch(successClient(CREATE_USER, response));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(CREATE_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
} 

export function deleteUser(user_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_USER));
		return client.service('noxmon-user')
			.remove(user_id)
			.then(response => {
				dispatch(successClient(DELETE_USER, response));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(DELETE_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function patchUser(user_id, data) {
	if (data.resend_registration) {
		return dispatch => {
			dispatch(requestClient(RESEND_EMAIL));
			return client.service('noxmon-user')
				.patch(user_id, data)
				.then(response => {
					dispatch(successClient(RESEND_EMAIL));
				}).catch(error => {
					dispatch(failureClient(RESEND_EMAIL, error));
					console.log("Error: ", error);
					return null;
				});
		}
	}
	return dispatch => {
		dispatch(requestClient(PATCH_USER));
		return client.service('noxmon-user')
			.patch(user_id, data)
			.then(response => {
				dispatch(successClient(PATCH_USER));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(PATCH_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function resetUserEmailStatus() {
	return dispatch => dispatch({
		type: RESET_USER_EMAIL_STATUS
	})
}


