import React from 'react';

const AdminDashboard = React.lazy(() => import('../views/Dashboard/AdminDashboard'));

const admin_routes = [
  { path: '/', exact: true, name: 'Home'},
  { path: '/admin/', exact: true, name: 'Dashboard', component: AdminDashboard },
];

export default admin_routes;
