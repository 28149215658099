import React, { Component, Fragment } from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { Redirect } from 'react-router-dom';
class HeaderRight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) 
      return <Redirect to='/documentation' />;

    return (
      <Fragment>
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink onClick={() => this.setState({ redirect: true })} ><i className="icon-info"></i></NavLink>
          </NavItem>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <i className="icon-user" style={{ fontSize: '22px' }}/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center" style={{ backgroundColor: 'white'}}>

                <div className="d-block">
                  <div className="h4">{this.props.username}</div>
                  <div className="d-flex">
                    <div className="h5 pr-1">Email: </div>
                    <div>{this.props.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="h5 pr-1">Role: </div>
                    <div className="text-capitalize">{this.props.role}</div>
                  </div>       
                </div>
              </DropdownItem>

              <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem onClick={e => this.props.onLogout(e)}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

        </Nav>
      </Fragment>
    );
  }
}

export default HeaderRight;
