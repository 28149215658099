import client from '../../utils/feathers';

export const ADMIN_CLIENT_REQUEST = 'CLIENT_REQUEST';
export const ADMIN_CLIENT_SUCCESS = 'CLIENT_SUCCESS';
export const ADMIN_CLIENT_FAILURE = 'CLIENT_FAILURE';

// GETS //
export const GET_ASMTS_REQUEST = 'GET_ASMTS_REQUEST';
export const GET_ASMTS_SUCCESS = 'GET_ASMTS_SUCCESS';
export const GET_ASMTS_FAILURE = 'GET_ASMTS_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_PENDING_ACCOUNTS_REQUEST = 'GET_PENDING_ACCOUNTS_REQUEST';
export const GET_PENDING_ACCOUNTS_SUCCESS = 'GET_PENDING_ACCOUNTS_SUCCESS';
export const GET_PENDING_ACCOUNTS_FAILURE = 'GET_PENDING_ACCOUNTS_FAILURE';

// SETS //

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST'
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS'
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE'

export const DELETE_ASSESSMENT_REQUEST = 'DELETE_ASSESSMENT_REQUEST';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAILURE = 'DELETE_ASSESSMENT_FAILURE';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';

function requestClient(name) {
	return {
		type: ADMIN_CLIENT_REQUEST,
		name
	}
}

function successClient(name, data) {
	return {
		type: ADMIN_CLIENT_SUCCESS,
		name,
		data
	}
}

function failureClient(name, error) {
	return {
		type: ADMIN_CLIENT_FAILURE,
		name,
		error
	}
}

export function getAssessments() {
	return dispatch => {
		dispatch(requestClient(GET_ASMTS_REQUEST));
		return client.service('noxmon-assessment').find()
		.then(response => {
			dispatch(successClient(GET_ASMTS_SUCCESS, response));
		}).catch(error => {
			dispatch(failureClient(GET_ASMTS_FAILURE, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getUsers() {
	return dispatch => {
		dispatch(requestClient(GET_USERS_REQUEST));
		dispatch(getPendingAccounts());
		return client.service('noxmon-user').find()
		.then(response => {
			dispatch(successClient(GET_USERS_SUCCESS, response));
		}).catch(error => {
			dispatch(failureClient(GET_USERS_FAILURE, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getPendingAccounts() {
	return dispatch => {
		dispatch(requestClient(GET_PENDING_ACCOUNTS_REQUEST));
		return client.service('get-pending-accounts').find()
		.then(response => {
			dispatch(successClient(GET_PENDING_ACCOUNTS_SUCCESS, response));
		}).catch(error => {
			dispatch(failureClient(GET_PENDING_ACCOUNTS_FAILURE, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createUser(credentials) {
	const { username, email, role, company, manager_id, invited_by } = credentials;
	return dispatch => {
		dispatch(requestClient(CREATE_USER_REQUEST));
		return client.service('noxmon-user')
			.create({ 
				username: username, 
				email: email, 
				role: role,
				manager_id: manager_id,
				company: company,
				invited_by: invited_by
			}).then(response => {
				dispatch(successClient(CREATE_USER_SUCCESS));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(CREATE_USER_FAILURE, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function deleteUser(user_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_USER_REQUEST));
		return client.service('noxmon-user')
			.remove(user_id)
			.then(response => {
				dispatch(successClient(DELETE_USER_SUCCESS));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(DELETE_USER_FAILURE, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function editUser(user_id, field, value) {
	return dispatch => {
		dispatch(requestClient(EDIT_USER_REQUEST));
			return client.service('noxmon-user').patch(user_id, {
				[field]: value
			}).then (response => {
				dispatch(successClient(EDIT_USER_SUCCESS));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(EDIT_USER_FAILURE, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function resendUserVerificationEmail(user_id) {
	return dispatch => {
		dispatch(requestClient(RESEND_EMAIL_REQUEST));
			return client.service('resend-email').create({
				user_id,
				type: 'resend-verification-email'
			})
			.then (response => {
				dispatch(successClient(RESEND_EMAIL_SUCCESS));
			}).catch(error => {
				dispatch(failureClient(RESEND_EMAIL_FAILURE, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function deleteAssessment(asmt_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_ASSESSMENT_REQUEST));
		return client.service('noxmon-assessment').remove(asmt_id)
			.then(response => {
				dispatch(successClient(DELETE_ASSESSMENT_SUCCESS, response));
				dispatch(getAssessments())
			}).catch(error => {
				dispatch(failureClient(DELETE_ASSESSMENT_FAILURE, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function getOrganizations() {
	return dispatch => {
		dispatch(requestClient(GET_ORGANIZATIONS));
		return client.service('noxmon-organization').find()
		.then(response => {
			dispatch(successClient(GET_ORGANIZATIONS, response));
		}).catch(error => {
			dispatch(failureClient(GET_ORGANIZATIONS, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createOrganization(organization) {
	return dispatch => {
		dispatch(requestClient(CREATE_ORGANIZATION));
		return client.service('noxmon-organization')
			.create(organization).then(response => {
				dispatch(successClient(CREATE_ORGANIZATION));
				dispatch(getOrganizations());
			}).catch(error => {
				dispatch(failureClient(CREATE_ORGANIZATION, error));
				console.log("Error: ", error);
				return null;
			});
	}
}