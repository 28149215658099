import {
	MANAGER_CLIENT_REQUEST,
	MANAGER_CLIENT_SUCCESS,
	MANAGER_CLIENT_FAILURE,
} from '../../actions/manager/manager-client-actions'

import {
	RESET_ERROR
} from '../../actions/manager/assessment-structure-actions';

import { defaultProps, setFetching as utilSetFetching, setData, setError } from '../util';

const defaultState = {
	fetching: {
		patch_question: false,
		create_question: false,
		delete_question: false
	},

	error: null,
	errors: {
	},

	questions_by_group: defaultProps
}

const setFetching = (key, state, value) => {
  return {
    ...state,
    fetching: {
      ...state.fetching,
      [key]: value
    }
  }
}

const setErrors = (key, state, error, message = null) => {
	return {
    ...state,
    errors: {
      ...state.errors,
      [key]: {
				error,
				message
			}
    }
  }
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		case RESET_ERROR: {
			console.log(action)
		}

		// REQUESTS
		case MANAGER_CLIENT_REQUEST: {
			switch (action.name) {
				default:
					return state;
			}
		}

		// SUCCESSES
		case MANAGER_CLIENT_SUCCESS: {
			switch (action.name) {
				default:
					return state;
			}
		}

		// FAILURES
		case MANAGER_CLIENT_FAILURE: {
			switch (action.name) {
				default:
					return state;
			}
		}

		default:
			return state;
	}
}




