import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login, getProfile } from '../actions/auth-actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import auth from '../utils/auth'

const loading = <div className="animated fadeIn pt-3 text-center">Loading...</div>;

export const IsAuth = (props) => {
	const auth0 = useAuth0()
	const { getAccessTokenSilently } = auth0
	const user = useSelector((state) => state.authStore.user)
	const fetching_profile = useSelector((state) => state.authStore.fetching_profile)
	const dispatch = useDispatch()

	useEffect(() => {
		(async function login() {
			if (!auth0.isLoading && !auth0.user) {
				await auth0.loginWithRedirect();
			}
		})();
	}, [auth0.isLoading]);

	useEffect(() => {
		if (!auth0.isLoading && auth0.isAuthenticated && auth0.user && 
				(!user || user.external_id !== auth0.user.sub)) {
			dispatch(getProfile())
		}
	}, [auth0.isAuthenticated, user]);

	useEffect(() => {
		auth.setTokenGenerator(getAccessTokenSilently)
  }, [getAccessTokenSilently]);

	if (auth0.isLoading || fetching_profile || !user) {
		return (loading);
	} else if (auth0.isAuthenticated && auth0.user && user) {
		return props.children;
	} else {
		return <Redirect to="/login" />;
	}
}
// class IsAuth extends Component {
// 	componentDidMount() {
// 		console.log("IsAuth mounted", this.props)
// 	}

// 	componentDidUpdate() {
// 		console.log("IsAuth updated", this.props)

// 	}

// 	render() {
// 		const { auth0 } = this.props
// 		if (!auth0) {
// 			console.log("Error: no auth context.")
// 			return <Redirect to="/500" />;
// 		}
// 		console.log(auth0)
// 		if (auth0.isLoading) {
// 			return (loading);
// 		} else if (auth0.isAuthenticated && auth0.user && auth0.user.sub) {
// 			// this.props.getProfile()
// 			return this.props.children;
// 		} else {
// 			return <Redirect to="/login" />;
// 		}
// 		console.log(this.props)
// 		return this.props.children;
// 		if (this.props.auth_error) {
// 			return <Redirect to="/login" />;
// 		}
// 		if (this.props.fetching_login === true) {
// 			return (loading);
// 		}
// 		if (this.props.isAuthenticated){ 
// 				return this.props.children;
// 		}
// 		if (!this.props.reauth_checked) {
// 			this.props.checkAuthentication();
// 			return (loading);
// 		} else {
// 			return <Redirect to="/login" />;
// 		}
// 	}
// }

// function mapStateToProps(state) {
// 	return {
// 		user: state.authStore.user
// 		// isAuthenticated: state.authStore.isAuthenticated,
// 		// fetching_login: state.authStore.fetching_login,
// 		// reauth_checked: state.authStore.reauth_checked,
// 		// auth_error: state.authStore.authError
// 	}
// }

// export default connect(mapStateToProps, {getProfile})(withAuth0(IsAuth));