import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './manager-client-actions';
import { getAssessment, getAssessmentStructure } from '../common/assessment-actions';

export const GET_QUESTIONS_BY_GROUP = 'GET_QUESTIONS_BY_GROUP';
export const PATCH_QUESTION = 'PATCH_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';

export const CREATE_GENERIC_CATEGORY = 'CREATE_GENERIC_CATEGORY'
export const PATCH_GENERIC_CATEGORY = 'PATCH_GENERIC_CATEGORY'
export const DELETE_GENERIC_CATEGORY = 'DELETE_GENERIC_CATEGORY'

export const UPDATE_ASSESSMENT_TIERS = 'UPDATE_ASSESSMENT_TIERS'

export const RESET_ASSESSMENT_STRUCTURE = 'RESET_ASSESSMENT_STRUCTURE'
export const RESET_ERROR = 'RESET_ERROR'

export function resetAssessmentStructure(){	
	return {
		type: RESET_ASSESSMENT_STRUCTURE
	}
}

export function resetErrors(key){	
	return {
		type: RESET_ERROR,
		key
	}
}

export function getQuestionsByGroup(group_id) {
	return dispatch => {
		dispatch(requestClient(GET_QUESTIONS_BY_GROUP));
		return client.service('assessment-questions').find({
			query: {
				assessment_subcategory_id: group_id
			}
		}).then(response => {
			dispatch(successClient(GET_QUESTIONS_BY_GROUP, response));
		}).catch(error => {
			dispatch(failureClient(GET_QUESTIONS_BY_GROUP, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function createGenericCategory(data) {
	return dispatch => {
		dispatch(requestClient(CREATE_GENERIC_CATEGORY));
		return client.service('categories').create(data)
		.then(response => {
			dispatch(successClient(CREATE_GENERIC_CATEGORY, response));
			dispatch(getAssessmentStructure(data.assessment_id))
		}).catch(error => {
			dispatch(failureClient(CREATE_GENERIC_CATEGORY, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function patchGenericCategory(id, data) {
	return (dispatch, getState) => {
		dispatch(requestClient(PATCH_GENERIC_CATEGORY));
		return client.service('assessment-generic-categories').patch(id, data)
		.then(response => {
			dispatch(successClient(PATCH_GENERIC_CATEGORY, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessmentStructure(current_assessment.id))
			}
		}).catch(error => {
			dispatch(failureClient(PATCH_GENERIC_CATEGORY, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function deleteGenericCategory(id) {
	return (dispatch, getState) => {
		dispatch(requestClient(DELETE_GENERIC_CATEGORY));
		return client.service('assessment-generic-categories').remove(id)
		.then(response => {
			dispatch(successClient(DELETE_GENERIC_CATEGORY, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessmentStructure(current_assessment.id))
			}
		}).catch(error => {
			dispatch(failureClient(DELETE_GENERIC_CATEGORY, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function updateAssessmentTiers(data) {
	return (dispatch, getState) => {
		dispatch(requestClient(UPDATE_ASSESSMENT_TIERS));
		return client.service('noxmon-assessment-structure').update(data.assessment_id, { type: 'tiers', tiers: data.tiers })
		.then(response => {
			dispatch(successClient(UPDATE_ASSESSMENT_TIERS, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessment(current_assessment.id));
			}
		}).catch(error => {
			dispatch(failureClient(UPDATE_ASSESSMENT_TIERS, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function patchQuestion(id, data) {
	return (dispatch, getState) => {
		dispatch(requestClient(PATCH_QUESTION));
		return client.service('assessment-generic-questions').patch(id, data)
		.then(response => {
			dispatch(successClient(PATCH_QUESTION, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessmentStructure(current_assessment.id))
			}
		}).catch(error => {
			dispatch(failureClient(PATCH_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createQuestion(new_question) {
	return (dispatch, getState) => {
		dispatch(requestClient(CREATE_QUESTION));
		return client.service('generic-questions').create(new_question)
		.then(response => {
			dispatch(successClient(CREATE_QUESTION, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessmentStructure(current_assessment.id))
			}
		}).catch(error => {
			dispatch(failureClient(CREATE_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestion(id) {
	return (dispatch, getState) => {
		dispatch(requestClient(DELETE_QUESTION));
		return client.service('assessment-generic-questions').remove(id)
		.then(response => {
			dispatch(successClient(DELETE_QUESTION, response));
			const current_assessment = getState().commonStore.assessment.data
			if (current_assessment) {
				dispatch(getAssessmentStructure(current_assessment.id))
			}
		}).catch(error => {
			dispatch(failureClient(DELETE_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	}
}
