import axios from 'axios';
import auth from './auth'

const client = axios
client.interceptors.request.use(async config => {
  const token = await auth.getAccessTokenSilently()
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` }
  }},
  error => {
    Promise.reject(error);
  }
)

export default client