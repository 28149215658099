import {
	ADMIN_CLIENT_REQUEST,
	ADMIN_CLIENT_SUCCESS,
	ADMIN_CLIENT_FAILURE,
	GET_ASMTS_REQUEST,
	GET_ASMTS_SUCCESS,
	GET_ASMTS_FAILURE,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
	GET_PENDING_ACCOUNTS_REQUEST,
	GET_PENDING_ACCOUNTS_SUCCESS,
	GET_PENDING_ACCOUNTS_FAILURE,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILURE,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	EDIT_USER_REQUEST,
	EDIT_USER_SUCCESS,
	EDIT_USER_FAILURE,
	RESEND_EMAIL_REQUEST,
	RESEND_EMAIL_SUCCESS,
	RESEND_EMAIL_FAILURE,
	DELETE_ASSESSMENT_REQUEST,
	DELETE_ASSESSMENT_SUCCESS,
	DELETE_ASSESSMENT_FAILURE,
	GET_ORGANIZATIONS,
	CREATE_ORGANIZATION
} from '../actions/admin/admin-client-actions';
import { defaultProps, setFetching, setData, setError } from './util'

const defaultState = {
	assessments: [],
	users: [],
	pending_accounts: [],

	fetching_asmts: false,
	fetching_users: false,
	fetching_create_user: false,
	fetching_delete_user: false,
	fetching_edit_user: false,
	fetching_delete_assessment: false,
	fetching_pending_accounts: false,
	fetching_resend_email: false,

	organizations: defaultProps,
	create_organization: defaultProps,

	resend_email_error: null,
	create_user_error: null,
	errorMessage: ''
}


export default (state=defaultState, action={}) => {
	switch (action.type) {
		// REQUESTS
		case ADMIN_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_ASMTS_REQUEST: {
					return {
						...state,
						fetching_asmts: true
					}
				}
				case GET_USERS_REQUEST: {
					return {
						...state,
						fetching_users: true
					}
				}
				case GET_ORGANIZATIONS: {
					return setFetching('organizations', state)
				}
				case CREATE_ORGANIZATION: {
					return setFetching('create_organization', state)
				}
				case GET_PENDING_ACCOUNTS_REQUEST: {
					return {
						...state,
						fetching_pending_accounts: true
					}
				}
				case CREATE_USER_REQUEST: {
					return {
						...state,
		        fetching_create_user: true,
		        create_user_error: null
					}
				}
				 case DELETE_USER_REQUEST: {
				 	return {
				 		...state,
				 		fetching_delete_user: true
				 	}
				 }
				 case EDIT_USER_REQUEST: {
				 	return {
				 		...state,
				 		fetching_edit_user: true
				 	}
				 }
				 case RESEND_EMAIL_REQUEST: {
					return {
						...state,
						fetching_resend_email: true
					}
				}
				 case DELETE_ASSESSMENT_REQUEST: {
				 	return {
				 		...state,
				 		fetching_delete_assessment: true
				 	}
				 }
				default:
					return state;
			}
		}

		// SUCCESSES
		case ADMIN_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_ASMTS_SUCCESS: {
					return {
						...state,
						fetching_asmts: false,
						assessments: action.data
					}
				}
				case GET_USERS_SUCCESS: {
					return {
						...state,
						fetching_users: false,
						users: action.data
					}
				}
				case GET_ORGANIZATIONS: {
					return setData('organizations', state, action.data)
				}
				case CREATE_ORGANIZATION: {
					return setData('create_organization', state, {})
				}
				case GET_PENDING_ACCOUNTS_SUCCESS: {
					return {
						...state,
						fetching_pending_accounts: false,
						pending_accounts: action.data
					}
				}
				case CREATE_USER_SUCCESS: {
					return {
						...state,
						fetching_create_user: false
					}
				}
				case DELETE_USER_SUCCESS: {
					return {
						...state,
						fetching_delete_user: false
					}
				}
				case EDIT_USER_SUCCESS: {
					return {
						...state,
						fetching_edit_user: false
					}
				}
				case RESEND_EMAIL_SUCCESS: {
					return {
						...state,
						fetching_resend_email: false
					}
				}
				case DELETE_ASSESSMENT_SUCCESS: {
					return {
						...state,
						fetching_delete_assessment: false,
						assessments: state.assessments.filter(asmt => asmt.id !== action.data.id)
					}
				}
				default:
					return state;
			}
		}

		// FAILURES
		case ADMIN_CLIENT_FAILURE: {
			switch (action.name) {
				case GET_ASMTS_FAILURE: {
					return {
						...state,
						fetching_asmts: false,
					}
				}
				case GET_USERS_FAILURE: {
					return {
						...state,
						fetching_users: false,
						errorMessage: action.error
					}
				}
				case GET_ORGANIZATIONS: {
					return setError('organizations', state, action.error)
				}
				case CREATE_ORGANIZATION: {
					return setError('create_organization', state, action.error)
				}
				case GET_PENDING_ACCOUNTS_FAILURE: {
					return {
						...state,
						fetching_pending_accounts: false,
						errorMessage: action.error
					}
				}
				case CREATE_USER_FAILURE: {
					return {
						...state,
						fetching_create_user: false,
						create_user_error: action.error
					}
				}
				case DELETE_USER_FAILURE: {
					return {
						...state,
						fetching_delete_user: false,
						errorMessage: action.error
					}
				}
				case EDIT_USER_FAILURE: {
					return {
						...state,
						fetching_edit_user: false,
						errorMessage: action.error
					}
				}
				case RESEND_EMAIL_FAILURE: {
					return {
						...state,
						fetching_resend_email: false,
						resend_email_error: action.error
					}
				}
				case DELETE_ASSESSMENT_FAILURE: {
					return {
						...state,
						fetching_delete_assessment: false,
						errorMessage: action.error
					}
				}
				default:
					return state;
			}
		}

		default:
			return state;
	}
}
