
import moment from 'moment';


export function groupBy(key, array) {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
}

export function sortOn(key, array) {
	// TODO
	return array;
}

export function getLastLoginMessage(activity) {
  if (activity.length === 1) {
    return "Welcome!"
  } else if (activity.length === 2) {
    return "Last sign on at " + moment(activity[1].createdAt).format('HH:mm, DD MMMM YYYY');
  } else {
    return "Error fetching last login."
  }
}

export function validatePassword(password) {
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  return strongRegex.test(password);
}

export function toLett(num_score) { // converts numerical score to letter
  switch (true) {
    case num_score <= 0: 
      return 'S';
    case num_score > 0 && num_score < 1.4: 
      return 'F';
    case num_score < 2.4:
      return 'D';
    case num_score < 3.4:
      return 'C';
    case (num_score < 4.4):
      return 'B';
    case num_score <= 5:
      return 'A';
    default:
      return 'S'; 
  }
}

export function getScoreColor(num_score) {
  switch (true) {
    case num_score > 0 && num_score < 2.0: 
      return '#f86c6b';
    case (num_score < 4.0):
      return '#ffc107';
    case num_score <= 5:
      return 'green';
    default:
      return ''; 
  }
}

export function getScoreColorName(num_score) {
  switch (true) {
    case !num_score || num_score === 0:
      return 'light';
    case num_score > 0 && num_score < 2.4:
      return 'danger';
    case num_score < 3.5:
      return 'warning';
    case num_score <= 6:
      return 'success';
    default:
      return 'light'; 
  }
}

export function getRiskLevelColor(risk_level_value) {
  switch (true) {
    case risk_level_value === 'High':
      return 'dark-blue';
    case risk_level_value === 'Medium':
      return 'blue';
    case risk_level_value === 'Low':
      return 'light-blue';
    default:
      return ''; 
  }
}

export function getRiskLevelSecondaryColor(risk_level_value) {
  switch (true) {
    case risk_level_value === 'High':
      return 'danger';
    case risk_level_value === 'Medium':
      return 'warning';
    case risk_level_value === 'Low':
      return 'success';
    default:
      return ''; 
  }
}

export function getFunctionIcon(function_name) {
  switch (function_name) {
    case 'IDENTIFY':
      return 'icon-direction';
    case 'PROTECT':
      return 'icon-shield';
    case 'DETECT':
      return 'icon-eye';
    case 'RESPOND':
      return 'icon-key';
    case 'RECOVER':
      return 'icon-support';
    default:
      return 'icon-list';
  }
}

export function getProgressColor(val) {
  switch (true) {
    case (val < 2.0): 
      return "danger";
    case (val < 4.0):
      return "warning";
    default:
      return "success";
  }
}

export function getPercentageCompleted(num_questions, num_answered) {
  return ((num_answered / num_questions) * 100).toFixed();
}

export function formatTime(timestamp) {
  return new Date(timestamp).toLocaleString([], {dateStyle: 'long', timeStyle:'short'})
}

export function deepFind(parent_arr, target_prop, target, child_prop = 'children') {
  let result = parent_arr.find(parent => parent[target_prop] === target)
  if (!result) {
    parent_arr.some(parent => {
      if (parent[child_prop] && parent[child_prop].length > 0) {
        result = deepFind(parent[child_prop], target_prop, target, child_prop)
        return !!result
      } else {
        return false
      }
    })
  }
  return result
}

export function isReadOnly(user) {
  if (!user || !user.permissions) {
    return true
  }
  return !!user.permissions.find(permission => permission.name === 'audit')
}


export function getUserRole(user) {
  if (user.role === 'analyst') {
    return 'Analyst'
  } else if (user.role === 'admin') {
    return 'Admin'
  } else if (user.permissions && user.permissions.length > 0) {
    if (user.permissions[0].name === 'full') {
      return 'Full Manager'
    } else if (user.permissions[0].name === 'audit') {
      return 'Audit Manager (Read-Only)'
    } else {
      return 'Assessor Manager'
    }
  } else {
    return ''
  }
}

export function getOrganizationName(el, organizations) {
  if (organizations && organizations.length > 0) {
    const org = organizations.find(org => org.id === el.organization_id)
    if (org && org.name) {
      return org.name
    }
  }
  return ''
}