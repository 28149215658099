import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './analyst-client-actions';

export const GET_QUESTION_CATEGORY = 'GET_QUESTION_CATEGORY';
export const GET_CATEGORY_COMMENTS = 'GET_CATEGORY_COMMENTS'; // TODO
export const PATCH_ANALYST_QUESTION = 'PATCH_ANALYST_QUESTION';

export const CREATE_QM = 'CREATE_QM';
export const UPDATE_QM = 'UPDATE_QM';
export const DELETE_QM = 'DELETE_QM';

export const UPLOAD_QM_DOCUMENT = 'UPLOAD_QM_DOCUMENT';
export const GET_QM_DOCUMENT = 'GET_QM_DOCUMENT';
export const DELETE_QM_DOCUMENT = 'DELETE_QM_DOCUMENT';

export function getQuestionCategory(id) {
	return (dispatch) => {
		dispatch(requestClient(GET_QUESTION_CATEGORY));
		return client.service('analyst-question-category').get(id)
		.then(response => {
			dispatch(successClient(GET_QUESTION_CATEGORY, response));
		}).catch(error => {
			dispatch(failureClient(GET_QUESTION_CATEGORY, error));
			console.log("Error: ", error);
			return null;
		})
	}
}

export function patchAnalystQuestion(user_question_id, data, params = {}) {
	return dispatch => {
		dispatch(requestClient(PATCH_ANALYST_QUESTION));
		return client.service('noxmon-user-question').patch(user_question_id, data, params)
		.then(response => {
			dispatch(successClient(PATCH_ANALYST_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(PATCH_ANALYST_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}


export function createQuestionMethod(data) {
	const { document, ...method_data } = data
	return dispatch => {
		dispatch(requestClient(CREATE_QM));
		return client.service('question-methods').create(method_data)
		.then(response => {
			dispatch(successClient(CREATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(response.updated_question_method_id, document))
			}
		}).catch(error => {
			dispatch(failureClient(CREATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function updateQuestionMethod(id, updated) {
	const { document, ...method_data } = updated
	return dispatch => {
		dispatch(requestClient(UPDATE_QM));
		return client.service('question-methods').patch(id, method_data)
		.then(response => {
			dispatch(successClient(UPDATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(id, document))
			}
		}).catch(error => {
			dispatch(failureClient(UPDATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethod(id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM));
		return client.service('question-methods').remove(id)
		.then(response => {
			dispatch(successClient(DELETE_QM, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function uploadQuestionMethodDocument(question_method_id, file) {
	return dispatch => {
		dispatch(requestClient(UPLOAD_QM_DOCUMENT));
		const fData = new FormData();
		fData.append('filename', 'x');
		fData.append('file', file);
		fData.append('question_method_id', question_method_id);
		return client.service('noxmon-question-method-documents').create(fData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			dispatch(successClient(UPLOAD_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(UPLOAD_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getQuestionMethodDocument(question_method_id) {
	return dispatch => {
		dispatch(requestClient(GET_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').get(question_method_id)
		.then(response => {
			dispatch(successClient(GET_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(GET_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethodDocument(document_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').remove(document_id)
		.then(response => {
			console.log(response)
			dispatch(successClient(DELETE_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}