export const MANAGER_CLIENT_REQUEST = 'MANAGER_CLIENT_REQUEST';
export const MANAGER_CLIENT_SUCCESS = 'MANAGER_CLIENT_SUCCESS';
export const MANAGER_CLIENT_FAILURE = 'MANAGER_CLIENT_FAILURE';

export function requestClient(name, param) {
	return {
		type: MANAGER_CLIENT_REQUEST,
		name,
		param
	}
}

export function successClient(name, data) {
	return {
		type: MANAGER_CLIENT_SUCCESS,
		name,
		data
	}
}

export function failureClient(name, error) {
	return {
		type: MANAGER_CLIENT_FAILURE,
		name,
		error
	}
}