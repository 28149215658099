import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { logout } from '../../actions/auth-actions';
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux';

import { Container } from 'reactstrap';
import {
  AppFooter,
  AppHeader
} from '@coreui/react';

const Footer = React.lazy(() => import('./Footer'));
const HeaderNoSidebar = React.lazy(() => import('./HeaderNoSidebar'));

const LayoutNoSidebar = (props) => {
  const { logout: auth0Logout } = useAuth0()
	const dispatch = useDispatch()
	const user = useSelector((state) => state.authStore.user)
  const loading = <div className="animated fadeIn pt-1 text-center">Loading...</div>

  useEffect(() => {
    // body classes added when sidebar layout is used not removed by default
    document.body.classList.remove('sidebar-lg-show');
    document.body.classList.remove('sidebar-fixed');
    document.body.classList.remove('sidebar-minimized');
    document.body.classList.remove('brand-minimized');
  })

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logout())
    auth0Logout()
  }

  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={loading}>
          <HeaderNoSidebar 
            onLogout={e => signOut(e)}
            username={user.username}
            role={user.role}
            email={user.email}
          />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <main className="main">
          <Container fluid className="main-container">
            <Suspense fallback={loading}>
              <Switch>
                {props.routes && props.routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component {...props} />
                      )} />
                  ) : (null);
                })}
                <Redirect from="/analyst" to="/analyst/dashboard/" />
                <Redirect from="/manager" to="/manager/dashboard/" />
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      <AppFooter style={{ backgroundColor:'white' }}>
        <Suspense fallback={loading}>
          <Footer />
        </Suspense>
      </AppFooter>
    </div>
  );
}

export default LayoutNoSidebar