import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react';
import { isReadOnly } from '../../utils/utilityFunctions';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.getNavConfig = this.getNavConfig.bind(this)

    this.state = {
      current_assessment_category_id: null
    }
  }

  componentDidUpdate() {
    const match = this.props.location.pathname.match(/.*(?:category\/)(\d*)$/)
    if (match && match.length === 2 && match[1] !== this.state.current_assessment_category_id) {
      this.setState({
        current_assessment_category_id: match[1]
      })
    } else if (!match && this.state.current_assessment_category_id) {
      this.setState({
        current_assessment_category_id: null
      })
    }
  }

  getCategoryStructure(cat, role, assessment_id) {
    if ((role === 'manager' && cat.is_question_category) || (role === 'analyst' && !cat.children)) {
      return {
        name: cat.name,
        url: `/${role}/assessment/${assessment_id}/subcategory/${cat.assessment_category_id}`
      }
    } else {
      let classnames = "d-inline-block w-50"
      if (this.state.current_assessment_category_id === cat.assessment_category_id.toString()) {
        classnames += " active color-light-blue"
      }
      let url = `/${role}/assessment/${assessment_id}/category/${cat.assessment_category_id}`
      if (this.state.current_assessment_category_id && 
            cat.child_category_ids &&
            cat.child_category_ids.includes(parseInt(this.state.current_assessment_category_id))) {
        url = this.props.location.pathname // if url prop === current, dropdown will be open
      }
      if (role === 'manager') {
        return {
          name: <div 
            className={classnames}
            onClick={() => {
              this.props.history.push(`/${role}/assessment/${assessment_id}/category/${cat.assessment_category_id}`)
            }}>
              {cat.name}
            </div>,
          url,
          children: cat.children.map(child => this.getCategoryStructure(child, role, assessment_id))
        }
      } else {
        return {
          name: <div className={classnames}>
            {cat.name}
          </div>,
        url,
        children: cat.children.map(child => this.getCategoryStructure(child, role, assessment_id))
        }
      }

    }
  }

  getNavConfig() {
    const { current_assessment, current_assessment_structure, user: { role } } = this.props
    let tree_nodes = []
    if (current_assessment_structure) {
      const { assessment_structure } = current_assessment_structure
      if (role === 'analyst')  {
        tree_nodes = assessment_structure.map(cat => this.getCategoryStructure(cat, 'analyst', current_assessment.id))
      } else if (role === 'manager') {
        tree_nodes = assessment_structure.map(cat => this.getCategoryStructure(cat, 'manager', current_assessment.id))
      } else {
        return ({ items: [] })
      }
    }
    const items = [
      {
        name: 'Home',
        url: '/dashboard',
        icon: 'cui-home',
      }, {
        name: current_assessment.name,
        title: true,
        icon: 'cui-chart'
      }, {
        name: 'Dashboard',
        url: `/${role}/assessment/${current_assessment.id}/dashboard`,
        icon: 'cui-speedometer'
      }
    ]
    if (role === 'manager') {
      items.push(
        {
          name: 'Settings',
          url: `/manager/assessment/${current_assessment.id}/settings`,
          icon: 'cui-settings',
        }
      )
      if (!isReadOnly(this.props.user)) {
        items.push(
          {
            name: 'Template',
            url: `/manager/assessment/${current_assessment.id}/structure`,
            icon: 'cui-applications-settings',
          }, {
            name: 'Assignments',
            url: `/manager/assessment/${current_assessment.id}/assign`,
            icon: 'cui-user-follow',
          }
        )
      }
      items.push(
        {
          name: 'Scoring',
          url: `/manager/assessment/${current_assessment.id}/score-details`,
          icon: 'cui-chart'
        }, {
          name: 'questions',
          title: true,
          icon: 'cui-chart'
        }
      )
    }
    if (tree_nodes && tree_nodes.length > 0) {
      items.push({
        name: 'INHERENT STATE',
        icon: 'cui-storage',
        url: this.props.location.pathname,
        children: tree_nodes
      })
    } 
    return ({
      items
    })
  }

  render() {
    const { fetching_current_assessment, dispatch, ...props } = this.props
    let navConfig = {}
    if (fetching_current_assessment || !this.props.current_assessment) {
      navConfig = { items: [] }
    } else {
      navConfig = this.getNavConfig(this.props.location.pathname)
    }

    return (
      <Fragment>
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav navConfig={navConfig} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authStore.user,
    current_assessment: state.commonStore.assessment.data,
    fetching_current_assessment: state.commonStore.assessment.fetching,
    current_assessment_structure: state.commonStore.assessment_structure.data
  }
}

export default connect(mapStateToProps)(Sidebar);
