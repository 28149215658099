import {
	ANALYST_CLIENT_REQUEST,
	ANALYST_CLIENT_SUCCESS,
	ANALYST_CLIENT_FAILURE,
} from '../../actions/analyst/analyst-client-actions';

import {
	GET_SCORE
} from '../../actions/analyst/assessment-actions';

const defaultState = {
	score: null,

	fetching_score: false,

	errorMessage: ''
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		case ANALYST_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_SCORE: {
					return {
						...state,
						fetching_score: true
					}
				}
				default:
					return state;
			}
		}

		case ANALYST_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_SCORE: {
					return {
						...state,
						fetching_score: false,
						score: action.data
					}
				}
				default:
					return state;
			}
		}

		case ANALYST_CLIENT_FAILURE: {
			console.log("Failed: ", action);
			switch (action.name) {
				case GET_SCORE: {
					return {
						...state,
						fetching_score: false,
						errorMessage: action.error
					}
				}
				default:
					return state;
			}
		}

		default:
			return state;
	}
}