import { combineReducers } from 'redux';
import QuestionReducer from './question-reducer';
import AssessmentReducer from './assessment-reducer';

const reducers = {
	questionStore: QuestionReducer,
	assessmentStore: AssessmentReducer
}

const analystReducer = combineReducers(reducers);

export default analystReducer;