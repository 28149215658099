import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './manager-client-actions';
import { getAssessment, getAssessments, resetAssessment } from '../common/assessment-actions';

export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GET_TEMPLATES = 'GET_TEMPLATES';

export const CREATE_ASMT = 'CREATE_ASMT';
export const UPDATE_ASMT = 'UPDATE_ASMT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';

export const ASSIGN_USER = 'ASSIGN_USER';
export const UNASSIGN_USER = 'UNASSIGN_USER';

export const UPDATE_SECDOM = 'UPDATE_SECDOM';
export const CREATE_SECDOM = 'CREATE_SECDOM';
export const DELETE_SECDOM = 'DELETE_SECDOM';

export const GET_DIMENSION_GROUPS = 'GET_DIMENSION_GROUPS';

// gets
// export function getAsmts(user_id) {
// 	return dispatch => {
// 		dispatch(requestClient(GET_ASSESSMENTS));
// 		return client.service('noxmon-assessment').find()
// 		.then(response => {
// 			dispatch(successClient(GET_ASSESSMENTS, response));
// 		}).catch(error => {
// 			dispatch(failureClient(GET_ASSESSMENTS, error));
// 			return null;
// 		});
// 	}
// }

export function getTemplates() {
	return dispatch => {
		dispatch(requestClient(GET_TEMPLATES));
		return client.service('noxmon-template').find()
		.then(response => {
			dispatch(successClient(GET_TEMPLATES, response));
		}).catch(error => {
			dispatch(failureClient(GET_TEMPLATES, error));
			return null;
		})
	}
}

// edit assessment
export function createAsmt(newAsmt) {
	return dispatch => {
		dispatch(requestClient(CREATE_ASMT));
		return client.service('noxmon-assessment').create(newAsmt)
			.then(response => {
				dispatch(successClient(CREATE_ASMT, response));
				dispatch(getAssessments(newAsmt.manager_id));
			}).catch(error => {
				dispatch(failureClient(CREATE_ASMT, error.message));
				return null;
			});
	}
}

export function patchAsmt(asmt_id, field, value) {
	return dispatch => {
		dispatch(requestClient(UPDATE_ASMT));
		return client.service('noxmon-assessment').patch( asmt_id, {
				[field]: value
		}).then(response => {
			dispatch(successClient(UPDATE_ASMT));
			dispatch(resetAssessment()); // TODO: don't refetch -- handle in reducer
		}).catch(error => {
			dispatch(failureClient(UPDATE_ASMT, error));
			return null;
		});
	}
}

export function deleteAssessment(asmt_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_ASSESSMENT));
		return client.service('noxmon-assessment').remove(asmt_id)
			.then(response => {
				dispatch(successClient(DELETE_ASSESSMENT, response));
			}).catch(error => {
				dispatch(failureClient(DELETE_ASSESSMENT, error));
				return null;
			});
	}
}

// users
export function assignUser(user_id, assessment_id) {
	return dispatch => {
		dispatch(requestClient(ASSIGN_USER));
		return client.service('noxmon-user-assessment').create({
			user_id: user_id,
			assessment_id
		})
		.then(response => {
			dispatch(successClient(ASSIGN_USER, response));
			dispatch(getAssessment(assessment_id));
		}).catch(error => {
			dispatch(failureClient(ASSIGN_USER, error));
			return null;
		});
	}
}

export function unassignUser(user_asmt_id, assessment_id) {
	return dispatch => {
		dispatch(requestClient(UNASSIGN_USER));
		return client.service('noxmon-user-assessment').remove(user_asmt_id)
		.then(response => {
			dispatch(successClient(UNASSIGN_USER, response));
			dispatch(getAssessment(assessment_id));
		}).catch(error => {
			dispatch(failureClient(UNASSIGN_USER, error));
			return null;
		});
	}
}

// security domains

export function createSecurityDomain(security_domain) {
	const { name, description, assessment_id } = security_domain;
	return dispatch => {
		dispatch(requestClient(CREATE_SECDOM));
		return client.service('noxmon-security-domain')
			.create({ 
				"name": name, 
				"description": description, 
				"assessment_id": assessment_id
			}).then(response => {
				dispatch(successClient(CREATE_SECDOM));
				dispatch(getAssessment(assessment_id));
			}).catch(error => {
				dispatch(failureClient(CREATE_SECDOM, error));
					return null;
			});
	}
} 

export function updateSecurityDomain(security_domain) {
	const { id, name, description, assessment_id } = security_domain;
	return dispatch => {
		dispatch(requestClient(UPDATE_SECDOM));
		return client.service('noxmon-security-domain').patch( id, {
				"name": name,
				"description": description
		}).then(response => {
				dispatch(successClient(UPDATE_SECDOM));
				dispatch(getAssessment(assessment_id));
			}).catch(error => {
				dispatch(failureClient(UPDATE_SECDOM, error));
					return null;
			});
	}
}

export function deleteSecurityDomain(security_domain_id, assessment_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_SECDOM));
		return client.service('noxmon-security-domain').remove(security_domain_id)
		.then(response => {
				dispatch(successClient(DELETE_SECDOM));
				dispatch(getAssessment(assessment_id));
			}).catch(error => {
				dispatch(failureClient(DELETE_SECDOM, error));
					return null;
			});
	}
}

// dimensions

export function getDimensionGroups() {
	return dispatch => {
		dispatch(requestClient(GET_DIMENSION_GROUPS));
		return client.service('dimension-groups')
			.find().then(response => {
				dispatch(successClient(GET_DIMENSION_GROUPS, response));
			}).catch(error => {
				dispatch(failureClient(GET_DIMENSION_GROUPS, error));
					return null;
			});
	}
} 
