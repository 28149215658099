import {
	MANAGER_CLIENT_REQUEST,
	MANAGER_CLIENT_SUCCESS,
	MANAGER_CLIENT_FAILURE,
} from '../../actions/manager/manager-client-actions'

import {
	GET_ASSESSMENTS,
	GET_TEMPLATES,
	GET_DIMENSION_GROUPS,
	CREATE_ASMT,
	UPDATE_ASMT,
	ASSIGN_USER,
	UNASSIGN_USER,
	UPDATE_SECDOM,
	CREATE_SECDOM,
	DELETE_SECDOM,
	DELETE_ASSESSMENT
} from '../../actions/manager/assessment-actions';

const defaultState = {
	assessments: [],
	assessment_structure: null,
	templates: null,
	dimension_groups: null,

	fetching_create_asmt: false,
	fetching_asmts: false,
	fetching_asmt_update: false,
	fetching_assign_user: false,
	fetching_secdom: false,
	fetching_delete_assessment: false,
	fetching_score: false,
	fetching_templates: false,
	fetching_dimension_groups: false,

	error: ''
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		// REQUESTS
		case MANAGER_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_ASSESSMENTS: {
					return {
						...state,
						fetching_asmts: true
					}
				}
				case GET_TEMPLATES: {
					return {
						...state,
						fetching_templates: true
					}
				}
				case GET_DIMENSION_GROUPS: {
					return {
						...state,
						fetching_dimension_groups: true
					}
				}
				case UPDATE_ASMT: {
					return {
						...state,
						fetching_asmt_update: true
					}
				}
				case CREATE_ASMT: {
					return {
						...state,
		        fetching_create_asmt: true
					}
				}
				case ASSIGN_USER || UNASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: true
					}
				}
				case CREATE_SECDOM
						 || UPDATE_SECDOM
						 || DELETE_SECDOM: {
				 	return {
				 		...state,
				 		fetching_secdom: true
				 	}
				 }
				 case DELETE_ASSESSMENT: {
				 	return {
				 		...state,
				 		fetching_delete_assessment: true
				 	}
				 }
				default:
					return state;
			}
		}

		// SUCCESSES
		case MANAGER_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_ASSESSMENTS: {
					return {
						...state,
						fetching_asmts: false,
						assessments: action.data
					}
				}
				case GET_TEMPLATES: {
					return {
						...state,
						fetching_templates: false,
						templates: action.data
					}
				}
				case GET_DIMENSION_GROUPS: {
					return {
						...state,
						fetching_dimension_groups: false,
						dimension_groups: action.data
					}
				}
				case UPDATE_ASMT: {
					return {
						...state,
						fetching_asmt_update: false
					}
				}
				case CREATE_ASMT: {
					return {
						...state,
		        fetching_create_asmt: false
					}
				}
				case ASSIGN_USER: {
					return {
						...state,
						fetching_assign_user: false,
					}
				}
				case UNASSIGN_USER: {
					return {
						...state,
						fetching_assign_user: false,
					}
				}
				case UPDATE_SECDOM:
				case CREATE_SECDOM:
				case DELETE_SECDOM:
					return {
						...state,
						fetching_secdom: false
					}
				case DELETE_ASSESSMENT: {
					return {
						...state,
						fetching_delete_assessment: false,
						currentAssessment: null,
						assessments: state.assessments.filter(asmt => asmt.id !== action.data.id)
					}
				}
				default:
					return state;
			}
		}

		// FAILURES
		case MANAGER_CLIENT_FAILURE: {
			console.log("Failed: ", action);
			switch (action.name) {
				case GET_ASSESSMENTS: {
					return {
						...state,
						fetching_asmts: false,
					}
				}
				case GET_TEMPLATES: {
					return {
						...state,
						fetching_templates: false,
						error: action.error,
						templates: null
					}
				}
				case GET_DIMENSION_GROUPS: {
					return {
						...state,
						fetching_dimension_groups: false,
						error: action.error,
						dimension_groups: null
					}
				}
				case UPDATE_ASMT: {
					return {
						...state,
						fetching_asmt_update: false,
						error: action.error
					}
				}
				case CREATE_ASMT: {
					return {
						...state,
		        error: action.error,
		        fetching_create_asmt: false
					}
				}
				case ASSIGN_USER || UNASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: false,
		        error: action.error
					}
				}
				case UPDATE_SECDOM:
				case CREATE_SECDOM:
				case DELETE_SECDOM:
					return {
						...state,
		        fetching_secdom: false,
		        error: action.error
					}
				case DELETE_ASSESSMENT: {
					return {
						...state,
						fetching_delete_assessment: false,
						error: action.error
					}
				}
				default:
					return state;
			}
		}

		default:
			return state;
	}
}
