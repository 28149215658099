import React from 'react';

const ManagerDashboard = React.lazy(() => import('../views/Dashboard/ManagerDashboard'));
const ManagerAssessmentDashboard = React.lazy(() => import('../views/Manager/Assessment/AssessmentDashboard/ManagerAssessmentDashboard'));
const ManagerScoreDetails = React.lazy(() => import('../views/Manager/Assessment/ScoreDetails'));
const ManagerAssessmentSettings = React.lazy(() => import('../views/Manager/Assessment/AssessmentSettings'));
const SubcategoryView = React.lazy(() => import('../views/Manager/Assessment/SubcategoryEditor/SubcategoryView'));
const CategoryDashboard = React.lazy(() => import('../views/Manager/Assessment/CategoryDashboard/CategoryView'));
const AssessmentStructure = React.lazy(() => import('../views/Manager/Assessment/AssessmentStructure/AssessmentStructure'));
const BulkAssignment = React.lazy(() => import('../views/Manager/Assessment/BulkAssignment'));

const manager_routes = [
  { path: '/', exact: true, name: 'Home'}, 
  { path: '/manager/dashboard/', exact: true, name: 'Dashboard', component: ManagerDashboard },
  { path: '/manager/assessment/:id/dashboard', exact: true, name: 'Assessment', component: ManagerAssessmentDashboard },
  { path: '/manager/assessment/:id/settings', exact: true, name: 'Assessment Settings', component: ManagerAssessmentSettings },
  { path: '/manager/assessment/:id/structure/', exact: true, name: 'Assessment Structure', component: AssessmentStructure },
  { path: '/manager/assessment/:id/assign/', exact: true, name: 'Question Assignment', component: BulkAssignment },
  { path: '/manager/assessment/:id/score-details/', exact: true, name: 'Assessment', component: ManagerScoreDetails },
  { path: '/manager/assessment/:id/subcategory/:category_id', exact: true, name: 'Subcategory View', component: SubcategoryView },
  { path: '/manager/assessment/:id/category/:category_id', exact: true, name: 'Category View', component: CategoryDashboard }
];

export default manager_routes;
