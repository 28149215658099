import React, { Fragment } from 'react';
import HeaderRight from '../Shared/HeaderRight';
import HeaderNavBrand from '../Shared/HeaderNavBrand';

const HeaderNoSidebar = (props) => (
  <Fragment>
    <HeaderNavBrand />
    <HeaderRight 
      email={props.email}            
      onLogout={props.onLogout}
      username={props.username}
      role={props.role}
    />
  </Fragment>
);

export default HeaderNoSidebar;
