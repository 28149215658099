import { combineReducers } from 'redux';
import AuthReducer from './auth-reducer';
import ManagerReducers from './manager';
import AnalystReducers from './analyst';
import AdminClientReducer from './admin-client-reducer';
import ErrorReducer from './error-reducer';
import CommonReducer from './common-reducer';

const reducers = {
	authStore: AuthReducer,
	analystStore: AnalystReducers,
	managerStore: ManagerReducers,
	adminClientStore: AdminClientReducer,
	commonStore: CommonReducer,
	errorStore: ErrorReducer
}

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
	if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;