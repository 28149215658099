// Assumes a defaultState with the structure:
//   const defaultState = {
//      <key>: defaultProps
//   }

export const defaultProps = {
  data: null,
  fetching: false,
  error: false,
  error_message: null
}

export const setFetching = (key, state) => ({
  ...state,
  [key]: {
    ...defaultProps,
    fetching: true
  }
})

export const setData = (key, state, data) => ({
  ...state,
  [key]: {
    ...defaultProps,
    data
  }
})

export const setError = (key, state, message = '') => ({
  ...state,
  [key]: {
    ...defaultProps,
    error: true,
    error_message: message
  }
})

// export const updateAssessmentStructure = (type, state, new_category) => {
//   const old_tiers_data = state.assessment_structure.data.assessment_tiers
//   const old_structure_data = state.assessment_structure.data.assessment_structure
//   let new_structure_data
//   if (type === 'create') {
//     new_structure_data = old_structure_data.map(cat => {

//     })
//   }
//   return {
//     ...state,
//     assessment_structure: {
//       data: {
//         assessment_structure: new_structure_data,
//         assessment_tiers: old_tiers_data
//       },
//       fetching: false,
//       error: false,
//       error_message: null
//     }
//   }
// }