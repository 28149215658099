import { cloneDeep } from 'lodash';

import {
	MANAGER_CLIENT_REQUEST,
	MANAGER_CLIENT_SUCCESS,
	MANAGER_CLIENT_FAILURE,
} from '../../actions/manager/manager-client-actions';
import { defaultProps, setFetching, setData, setError } from '../util'

import {
	GET_ASSESSMENT_QUESTIONS,
	REMOVE_ASMT_QUESTION,
	EDIT_ASMT_QUESTION,
	ADD_ASMT_QUESTION,
	BULK_ASSIGN,
	BULK_UNASSIGN,
	GET_ASSIGNMENT_SUMMARY,
	GET_QUESTION_BANK
} from '../../actions/manager/question-actions';

const defaultState = {
	assessment_questions: [], // all questions with assignments
	assignment_summary: null,
	question_bank: defaultProps,

	fetching_questions: false,
	fetching_all_questions: false,
	fetching_assign_question: false,
	fetching_bulk_assign: false,
	fetching_bulk_unassign: false,
	fetching_delete_question: false,
	fetching_edit_question: false,
	fetching_add_question: false,
	fetching_assignment_summary: false,
	fetching_assignment_summary: false,

	error: ''
}

function updateQuestions(questions, new_question) {
	return questions.map(question => {
		if (question.id === new_question.id) {
			return new_question;
		} else {
			return question
		}
	})
}

// data is array of added user questions
function updateAssignmentSummary(original, data) {
	const { user_id, domain_id, dimension_id, assessment_id, assigned } = data;
	if (!original || original.assessment_id.toString() !== assessment_id.toString()) {
		return original;
	}
	const new_assignments = cloneDeep(original)
	if (!new_assignments.users[user_id]) {
		new_assignments.users[user_id] = {};
	}
	if (!new_assignments.users[user_id][dimension_id]) {
		new_assignments.users[user_id][dimension_id] = {};
	}
	if (!new_assignments.users[user_id][dimension_id][domain_id]) {
		new_assignments.users[user_id][dimension_id][domain_id] = {};
	}
	new_assignments.users[user_id][dimension_id][domain_id] = assigned;
	return new_assignments
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		// REQUESTS
		case MANAGER_CLIENT_REQUEST: {
			switch (action.name) {
				// case GET_QUESTIONS: {
				// 	return {
				// 		...state,
				// 		fetching_questions: true
				// 	}
				// }
				case GET_ASSESSMENT_QUESTIONS: {
					return {
						...state,
						fetching_all_questions: true
					}
				}
				case BULK_ASSIGN : {
					return {
						...state,
						fetching_bulk_assign: true
					}
				}
				case BULK_UNASSIGN : {
					return {
						...state,
						fetching_bulk_unassign: true
					}
				}
				case REMOVE_ASMT_QUESTION: {
				 	return {
				 		...state,
				 		fetching_delete_question: true
				 	}
				}
				case EDIT_ASMT_QUESTION: {
					return {
						...state,
						fetching_edit_question: true
					}
				}
				case ADD_ASMT_QUESTION: {
					return {
						...state,
						fetching_add_question: true
					}
				}
				case GET_ASSIGNMENT_SUMMARY: {
					return {
						...state,
						fetching_assignment_summary: true
					}
			  }
				case GET_QUESTION_BANK:
					return setFetching('question_bank', state)
				default:
					return state;
			}
		}

		// SUCCESSES
		case MANAGER_CLIENT_SUCCESS: {
			switch (action.name) {
				// case GET_QUESTIONS: {
				// 	return {
				// 		...state,
				// 		fetching_questions: false,
				// 		questions: action.data
				// 	}
				// }
				case GET_ASSESSMENT_QUESTIONS: {
					return {
						...state,
						fetching_all_questions: false,
						assessment_questions: action.data
					}
				}
				case BULK_ASSIGN: {
					return {
						...state,
						fetching_bulk_assign: false,
						// assignment_summary: updateAssignmentSummary(state.assignment_summary, action.data)
					}
				}
				case BULK_UNASSIGN: {
					return {
						...state,
						fetching_bulk_unassign: false,
						// assignment_summary: updateAssignmentSummary(state.assignment_summary, action.data)
					}
				}
				case REMOVE_ASMT_QUESTION: {
					// TODO: update current subcategory info so subcategory isn't refreshed
					const updated_questions = state.questions.filter(q => q.id !== action.data.question_id)
					return {
						...state,
						fetching_delete_question: false,
						questions: updated_questions
					}
				}
				case EDIT_ASMT_QUESTION: {
					return {
						...state,
						fetching_edit_question: false,
						questions: updateQuestions(state.questions, action.data)
					}
				}
				case ADD_ASMT_QUESTION: {
					const new_questions = state.questions
					new_questions.push(action.data)

					return {
						...state,
						fetching_add_question: false,
						questions: new_questions
					}
				}
				case GET_ASSIGNMENT_SUMMARY: {
					return {
						...state,
						fetching_assignment_summary: false,
						assignment_summary: action.data
					}
				}
				case GET_QUESTION_BANK:
					return setData('question_bank', state, action.data)
				default:
					return state;
			}
		}

		// FAILURES
		case MANAGER_CLIENT_FAILURE: {
			console.log("Failed: ", action);
			switch (action.name) {
				// case GET_QUESTIONS: {
				// 	return {
				// 		...state,
				// 		fetching_questions: false,
				// 		error: action.error
				// 	}
				// }
				case GET_ASSESSMENT_QUESTIONS: {
					return {
						...state,
						fetching_all_questions: false,
						error: action.error
					}
				}
				case BULK_ASSIGN: {
					return {
						...state,
						fetching_bulk_assign: false,
						error: action.error
					}
				}
				case BULK_UNASSIGN: {
					return {
						...state,
						fetching_bulk_unassign: false,
						error: action.error
					}
				}
				case REMOVE_ASMT_QUESTION: {
					return {
						...state,
						fetching_delete_question: false,
						error: action.error
					}
				}
				case EDIT_ASMT_QUESTION: {
					return {
						...state,
						fetching_edit_question: false,
						error: action.error
					}
				}
				case ADD_ASMT_QUESTION: {
					return {
						...state,
						fetching_add_question: false,
						error: action.error
					}
				}
				case GET_ASSIGNMENT_SUMMARY: {
					return {
						...state,
						fetching_assignment_summary: false,
						error: action.error
					}
				}
				case GET_QUESTION_BANK:
					return setError('question_bank', state, action.error)
				default:
					return state;
			}
		}

		default:
			return state;
	}
}
