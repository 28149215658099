export const ANALYST_CLIENT_REQUEST = 'ANALYST_CLIENT_REQUEST';
export const ANALYST_CLIENT_SUCCESS = 'ANALYST_CLIENT_SUCCESS';
export const ANALYST_CLIENT_FAILURE = 'ANALYST_CLIENT_FAILURE';

export function requestClient(name) {
	return {
		type: ANALYST_CLIENT_REQUEST,
		name
	}
}

export function successClient(name, data) {
	return {
		type: ANALYST_CLIENT_SUCCESS,
		name,
		data
	}
}

export function failureClient(name, error) {
	return {
		type: ANALYST_CLIENT_FAILURE,
		name,
		error
	}
}