import {
	MANAGER_CLIENT_REQUEST,
	MANAGER_CLIENT_SUCCESS,
	MANAGER_CLIENT_FAILURE,
} from '../../actions/manager/manager-client-actions';

import {
	GET_USERS,
	CREATE_USER,
	DELETE_USER,
	PATCH_USER,
	RESEND_EMAIL,
	RESET_USER_EMAIL_STATUS
} from '../../actions/manager/user-actions';
import {
	ASSIGN_USER,
	UNASSIGN_USER
} from '../../actions/manager/assessment-actions';
import { COMMON_CLIENT_FAILURE, COMMON_CLIENT_REQUEST, COMMON_CLIENT_SUCCESS } from '../../actions/common/common-client-actions';

const defaultState = {
	users: null,

	fetching_users: false,
	fetching_create_user: false,
	fetching_delete_user: false,
	fetching_assign_user: false,
	fetching_patch_user: false,
	fetching_resend_email: false,

	resend_email_success: false,
	resend_email_error: false,

	create_user_error: null,
	error: ''
}

export default (state=defaultState, action={}) => {
	switch (action.type) {
		case RESET_USER_EMAIL_STATUS: {
			return {
				...state,
				fetching_resend_email: false,
				resend_email_success: false,
				resend_email_error: false
			}
		}
		// REQUESTS
		case COMMON_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: true
					}
				}
			}
		}

		case MANAGER_CLIENT_REQUEST: {
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: true
					}
				}
				case CREATE_USER: {
					return {
						...state,
		        fetching_create_user: true,
		        create_user_error: null
					}
				}
				case DELETE_USER: {
				 	return {
				 		...state,
				 		fetching_delete_user: true
				 	}
				 }
				 case PATCH_USER: {
					return {
						...state,
						fetching_patch_user: true
					}
				}
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: true,
						resend_email_success: false,
						resend_email_error: false
					}
				}
				 case ASSIGN_USER || UNASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: true
					}
				}
				default:
					return state;
			}
		}

		// SUCCESSES
		case COMMON_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: false,
						users: action.data
					}
				}
			}
		}
		case MANAGER_CLIENT_SUCCESS: {
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: false,
						users: action.data
					}
				}
				case CREATE_USER: {
					return {
						...state,
						fetching_create_user: false
					}
				}
				case DELETE_USER: {
					return {
						...state,
						fetching_delete_user: false
					}
				}
				case PATCH_USER: {
					return {
						...state,
						fetching_patch_user: false
					}
				}
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: false,
						resend_email_success: true
					}
				}
				case ASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: false,
						users: handleAssignUser(state.users, action.data, action.name)
					}
				}
				case UNASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: false,
						users: handleAssignUser(state.users, action.data, action.name)
					}
				}
				default:
					return state;
			}
		}

		// FAILURES
		case COMMON_CLIENT_FAILURE: {
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: false,
						error: action.error
					}
				}
			}
		}
		case MANAGER_CLIENT_FAILURE: {
			console.log("Failed: ", action);
			switch (action.name) {
				case GET_USERS: {
					return {
						...state,
						fetching_users: false,
						error: action.error
					}
				}
				case CREATE_USER: {
					return {
						...state,
						fetching_create_user: false,
						create_user_error: action.error
					}
				}
				case DELETE_USER: {
					return {
						...state,
						fetching_delete_user: false,
						error: action.error
					}
				}
				case PATCH_USER: {
					return {
						...state,
						fetching_patch_user: false,
						error: action.error
					}
				}
				case RESEND_EMAIL: {
					return {
						...state,
						fetching_resend_email: false,
						resend_email_error: true
					}
				}
				case ASSIGN_USER || UNASSIGN_USER: {
					return {
						...state,
		        fetching_assign_user: false,
						error: action.error					
					}
				}
				default:
					return state;
			}
		}

		default:
			return state;
	}
}

function handleAssignUser (old_users, result, name) {
	return old_users.map(user => {
		if (user.id === result.user_id) {
			if (name === ASSIGN_USER) {
				return {
					...user,
					user_assessments: [...user.user_assessments, result]
				}
			} else {
				return {
					...user,
					user_assessments: user.user_assessments.filter(ua => ua.id !== result.id)
				}
			}
		} else {
			return user
		}
	})
}