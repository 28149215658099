import client from '../utils/feathers';

export const AUTH_CLIENT_REQUEST = 'AUTH_CLIENT_REQUEST';
export const AUTH_CLIENT_SUCCESS = 'AUTH_CLIENT_SUCCESS';
export const AUTH_CLIENT_FAILURE = 'AUTH_CLIENT_FAILURE';

export const GET_PROFILE = 'GET_PROFILE';
export const LOGIN = 'LOGIN';
export const PART_LOGIN_SUCCESS = 'PART_LOGIN_SUCCESS';
export const DUO_LOGIN = 'DUO_LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const REAUTH = 'REAUTH'

function requestClient(name) {
	return {
		type: AUTH_CLIENT_REQUEST,
		name
	}
}

function successClient(name, data) {
	return {
		type: AUTH_CLIENT_SUCCESS,
		name,
		data
	}
}

function failureClient(name, error) {
	return {
		type: AUTH_CLIENT_FAILURE,
		name,
		error
	}
}

export function getProfile() {
	return dispatch => {
		dispatch(requestClient(GET_PROFILE));
		return client.service('noxmon-profile').find()
		.then(response => {
			dispatch(successClient(GET_PROFILE, response));
		}).catch(error => {
			console.log(error)
			dispatch(failureClient(GET_PROFILE, error));
			return null;
		})
	}
}

export function login(credentials) {
	const { email, password } = credentials;
	return dispatch => {
		dispatch(requestClient(LOGIN));
		return client.authenticate({
			strategy: 'local',
			email, 
			password
		}).then(response => {
			window.localStorage.setItem('accessToken', response.accessToken);
			dispatch(successClient(PART_LOGIN_SUCCESS, response.sigResponse));
			return client.passport.verifyJWT(response.accessToken);
		}).then (payload => {
			return client.service('noxmon-user').get(payload.userId);
		}).then (user => {
			dispatch(successClient(LOGIN, user));
		}).catch(error => {
			dispatch(failureClient(LOGIN, error));
			return null;
		});
	}
}

export function duoLogin(sigResponse) {
	return dispatch => {
		dispatch(requestClient(DUO_LOGIN));
			return client.service('duo').create({
				action: 'verify_response',
				value: sigResponse
			}).then (response => {
				dispatch(successClient(DUO_LOGIN));
			}).catch(error => {
				dispatch(failureClient(DUO_LOGIN, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

// TODO: fix
export function register(user_id, credentials) {
	const { 
		first_name,
		last_name,
		address,
		zip,
		city,
		state,
		country,
		telephone
	} = credentials;
	return dispatch => {
		dispatch(requestClient(REGISTER));
			return client.service('noxmon-user').patch(user_id, {
				first_name,
				last_name,
				address,
				zip,
				city,
				state,
				country,
				telephone
			}).then (response => {
				console.log(response)
				dispatch(successClient(REGISTER));
				// redirect to login
			}).catch(error => {
				dispatch(failureClient(REGISTER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function logout() {
	return dispatch => {
		dispatch({ type: 'RESET_APP' });
		dispatch(requestClient(LOGOUT));
		window.localStorage.removeItem('accessToken');
		return null;
	}
}

export function checkAuthentication() {
	return dispatch => {
		dispatch(requestClient(REAUTH))
		return client.authenticate({
			strategy: 'jwt',
			accessToken: window.localStorage.accessToken
		}).then(response => {
			return client.passport.verifyJWT(response.accessToken);
		}).then (payload => {
			return client.service('noxmon-user').get(payload.userId);
		}).then (user => {
			// dispatch(successClient(LOGIN, user));
			dispatch(successClient(REAUTH, user));
		}).catch(error => {
			dispatch(failureClient(AUTH_FAILURE, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function resetPasswordWithReauthentication(credentials) {
	const { email, password, new_password } = credentials;

	return dispatch => {
		dispatch(requestClient(LOGIN));
		return client.authenticate({
			strategy: 'local',
			email, 
			password
		}).then(response => {
			window.localStorage.setItem('accessToken', response.accessToken);
			return client.passport.verifyJWT(response.accessToken);
		}).then (payload => {
			return client.service('noxmon-user').get(payload.userId);
		}).then (user => {
			dispatch(successClient(LOGIN, user));
			dispatch(requestClient(PASSWORD_CHANGE));
			return client.service('noxmon-user').patch(user.id, {
				password: new_password
			}).then (response => {
				dispatch(successClient(PASSWORD_CHANGE));
			}).catch(error => {
				dispatch(failureClient(PASSWORD_CHANGE, error));
				console.log("Error: ", error);
				return null;
			});
		}).catch(error => {
			dispatch(failureClient(LOGIN, error));
			return null;
		});
	}
}

export function resetPasswordNoReauthentication(credentials) {
		const { user_id, new_password } = credentials;
		return dispatch => {
			dispatch(requestClient(PASSWORD_CHANGE));
				return client.service('noxmon-user').patch(user_id, {
					'password': new_password
				}).then (response => {
					dispatch(successClient(PASSWORD_CHANGE));
				}).catch(error => {
					dispatch(failureClient(PASSWORD_CHANGE, error));
					console.log("Error: ", error);
					return null;
				});
		}
}

export function verifyEmail(token) {
	return dispatch => {
		dispatch(requestClient(REGISTER));
			return client.service('authManagement').create({
				action: 'verifySignupLong',
				value: token
			}).then (response => {
				dispatch(successClient(REGISTER));
			}).catch(error => {
				dispatch(failureClient(REGISTER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

