import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { logout } from '../../actions/auth-actions';
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux';

import { Container } from 'reactstrap';
import {
  AppFooter,
  AppHeader
} from '@coreui/react';

import Sidebar from '../Sidebar/Sidebar';
const Footer = React.lazy(() => import('../Layout/Footer'));
const SidebarHeader = React.lazy(() => import('./SidebarHeader'));

const SidebarLayout = (props) => {
  const { logout: auth0Logout } = useAuth0()
	const dispatch = useDispatch()
	const user = useSelector((state) => state.authStore.user)
  const loading = <div className="animated fadeIn pt-1 text-center">Loading...</div>

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logout())
    auth0Logout()
  }
  
  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense  fallback={loading}>
          <SidebarHeader 
            onLogout={e => signOut(e)}
            username={user.username}
            role={user.role}
            email={user.email}
          />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <Sidebar {...props} />
        <main className="main">
          <Container fluid className="main-container">
            <Suspense fallback={loading}>
              <Switch>
                {props.routes && props.routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component {...props} />
                      )} />
                  ) : (null);
                })}
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      <AppFooter style={{ backgroundColor:'white' }}>
        <Suspense fallback={loading}>
          <Footer />
        </Suspense>
      </AppFooter>
    </div>
  );
}

export default SidebarLayout;
