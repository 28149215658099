import { combineReducers } from 'redux';
import SubcategoryReducer from './subcategory-reducer';
import QuestionReducer from './question-reducer';
import UserReducer from './user-reducer';
import AssessmentReducer from './assessment-reducer';
import AssessmentStructureReducer from './assessment-structure-reducer';

const reducers = {
	subcategoryStore: SubcategoryReducer,
	questionStore: QuestionReducer,
	assessmentStore: AssessmentReducer,
	assessmentStructureStore: AssessmentStructureReducer,
	userStore: UserReducer,
}

const managerReducer = combineReducers(reducers);

export default managerReducer;