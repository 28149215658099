import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";

import App from './App';
import store from './utils/store';

import './index.css';
import * as serviceWorker from './utils/serviceWorker';

const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://www.noxmon.io"
const domain = process.env.REACT_APP_AUTH0_DOMAIN || "noxmon-io.us.auth0.com"
const clientId = process.env.REACT_APP_AUTH0_APP_CLIENT_ID

ReactDOM.render(
	<Auth0Provider
		domain={domain}
		clientId={clientId}
		redirectUri={window.location.origin}
		audience={audience}
    // scope="read:current_user update:current_user_metadata"
		onRedirectCallback={(state) => {}}
	>
		<BrowserRouter>
			<Provider store={store}>
				<App />	
			</Provider>
		</BrowserRouter>
	</Auth0Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
