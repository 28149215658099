import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './manager-client-actions';

export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const PATCH_USER = 'PATCH_USER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESET_USER_EMAIL_STATUS = 'RESET_USER_EMAIL_STATUS';

export function getUsers() {
	return (dispatch, getState) => {
		dispatch(requestClient(GET_USERS));
		const manager_id = getState().authStore.user.id
		return client.service('noxmon-user').find({ 
			query: { manager_id } 
		}).then(response => {
			dispatch(successClient(GET_USERS, response));
		}).catch(error => {
			dispatch(failureClient(GET_USERS, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function createUser(credentials) {
	const { username, email, role, company, manager_id, invited_by } = credentials;
	return dispatch => {
		dispatch(requestClient(CREATE_USER));
		return client.service('noxmon-user')
			.create({ 
				username: username, 
				email: email, 
				role: role,
				manager_id: manager_id,
				company: company,
				invited_by: invited_by
			}).then(response => {
				dispatch(successClient(CREATE_USER));
				dispatch(getUsers(manager_id));
			}).catch(error => {
				dispatch(failureClient(CREATE_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
} 

export function patchUser(user_id, data) {
	if (data.resend_registration) {
		return dispatch => {
			dispatch(requestClient(RESEND_EMAIL));
			return client.service('noxmon-user')
				.patch(user_id, data)
				.then(response => {
					dispatch(successClient(RESEND_EMAIL));
				}).catch(error => {
					dispatch(failureClient(RESEND_EMAIL, error));
					console.log("Error: ", error);
					return null;
				});
		}
	}
	return dispatch => {
		dispatch(requestClient(PATCH_USER));
		return client.service('noxmon-user')
			.patch(user_id, data)
			.then(response => {
				dispatch(successClient(PATCH_USER));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(PATCH_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

export function resetUserEmailStatus() {
	return dispatch => dispatch({
		type: RESET_USER_EMAIL_STATUS
	})
}

export function deleteUser(user_id) {
	return (dispatch, getState) => {
		dispatch(requestClient(DELETE_USER));
		const current_user_id = getState().authStore.user.id
		if (current_user_id === user_id) {
			dispatch(failureClient(DELETE_USER, 'User cannot delete themself'));
			return null;
		}

		return client.service('noxmon-user')
			.remove(user_id)
			.then(response => {
				dispatch(successClient(DELETE_USER));
				dispatch(getUsers());
			}).catch(error => {
				dispatch(failureClient(DELETE_USER, error));
				console.log("Error: ", error);
				return null;
			});
	}
}

