import React from 'react';
import { reset } from '../actions/error-actions';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth-actions';

const ErrorSnackbar = (props) => {
	const { logout: auth0Logout } = useAuth0()
	const dispatch = useDispatch()
	const errors = useSelector((state) => state.errorStore)

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logout())
    auth0Logout()
  }

	let action_button = (					
		<Button 
			className="btn-light"
			onClick={() => dispatch(reset(props.errorName))}
		>
			<i className="icon-close" />  
		</Button>
	)
	if (props.errorName === 'loginError') {
		action_button = (					
			<Button 
				className="btn-light"
				onClick={e => signOut(e)}
			>
				Log out
			</Button>
		)
	}
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			open={errors[props.errorName]}
			autoHideDuration={6000}
			onClose={() => dispatch(reset(props.errorName))}
		>
			<SnackbarContent
			message={<span>{props.message}</span>}
			className="bg-danger"
			action={action_button}
			/>
		</Snackbar>
	);
}

export default ErrorSnackbar;