import client from '../../utils/feathers';
import { requestClient, successClient, failureClient } from './manager-client-actions';

export const GET_QUESTION_CATEGORY = 'GET_QUESTION_CATEGORY';
export const MANAGER_PATCH_QUESTION = 'MANAGER_PATCH_QUESTION';
export const ASSIGN_QUESTION = 'ASSIGN_QUESTION';
export const UNASSIGN_QUESTION = 'UNASSIGN_QUESTION';
export const GET_COMMENTS = 'GET_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const CREATE_QM = 'CREATE_QM';
export const UPDATE_QM = 'UPDATE_QM';
export const DELETE_QM = 'DELETE_QM';

export const UPLOAD_QM_DOCUMENT = 'UPLOAD_QM_DOCUMENT';
export const GET_QM_DOCUMENT = 'GET_QM_DOCUMENT';
export const DELETE_QM_DOCUMENT = 'DELETE_QM_DOCUMENT';

export function getQuestionCategory(id) {
	return (dispatch) => {
		dispatch(requestClient(GET_QUESTION_CATEGORY));
		return client.service('manager-question-category').get(id)
		.then(response => {
			dispatch(successClient(GET_QUESTION_CATEGORY, response));
		}).catch(error => {
			dispatch(failureClient(GET_QUESTION_CATEGORY, error));
			console.log("Error: ", error);
			return null;
		})
	}
}

export function patchQuestion(user_question_id, data, params = {}) {
	return dispatch => {
		dispatch(requestClient(MANAGER_PATCH_QUESTION, user_question_id));
		return client.service('noxmon-user-question').patch(user_question_id, data, params)
		.then(response => {
			dispatch(successClient(MANAGER_PATCH_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(MANAGER_PATCH_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function assignQuestion(user_id, assessment_question_id, dimension, domain, asmt_id) {
	return dispatch => {
		const params = {
				"user_id": user_id,
				"assessment_question_id": assessment_question_id,
				"dimension": dimension,
				"domain": domain,
				"assessment_id": asmt_id
			};
		dispatch(requestClient(ASSIGN_QUESTION));
		return client.service('noxmon-user-question').create(params)
		.then(response => {
			dispatch(successClient(ASSIGN_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(ASSIGN_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

export function unassignQuestion(id) {
	return dispatch => {
		dispatch(requestClient(UNASSIGN_QUESTION));
		return client.service('noxmon-user-question').remove(id)
		.then(response => {
			dispatch(successClient(UNASSIGN_QUESTION, response));
		}).catch(error => {
			dispatch(failureClient(UNASSIGN_QUESTION, error));
			console.log("Error: ", error);
			return null;
		});
	} 
}

// Comments functions
export function getComments(assessment_subcategory_id) {
	return dispatch => {
		dispatch(requestClient(GET_COMMENTS));
		return client.service('subcategory-comments').find({
				query: {
					assessment_subcategory_id: assessment_subcategory_id,
					$sort: {
						createdAt: -1
					}
				}
			}).then(response => {
				dispatch(successClient(GET_COMMENTS, response));
			}).catch(error => {
				console.log(error);
				dispatch(failureClient(GET_COMMENTS, error.message));
				return null;
			});
	}
}

export function postComment(new_comment) {
	return dispatch => {
		dispatch(requestClient(POST_COMMENT));
		return client.service('subcategory-comments').create(new_comment)
			.then(response => {
				dispatch(successClient(POST_COMMENT, response));
			}).catch(error => {
				console.log(error);
				dispatch(failureClient(POST_COMMENT, error.message));
				return null;
			});
	}
}

// export function editComment(comment_id, new_text) {
// 	return dispatch => {
// 		dispatch(requestClient(EDIT_COMMENT));
// 		return client.service('subcategory-comments').patch(comment_id, { text: new_text })
// 			.then(response => {
// 				dispatch(successClient(EDIT_COMMENT, response));
// 			}).catch(error => {
// 				console.log(error);
// 				dispatch(failureClient(EDIT_COMMENT, error.message));
// 				return null;
// 			});
// 	}
// }

export function deleteComment(comment_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_COMMENT));
		return client.service('subcategory-comments').remove(comment_id)
			.then(response => {
				dispatch(successClient(DELETE_COMMENT, response));
			}).catch(error => {
				console.log(error);
				dispatch(failureClient(DELETE_COMMENT, error.message));
				return null;
			});
	}
}

export function createQuestionMethod(data) {
	const { document, ...method_data } = data
	return dispatch => {
		dispatch(requestClient(CREATE_QM));
		return client.service('question-methods').create(method_data)
		.then(response => {
			dispatch(successClient(CREATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(response.updated_question_method_id, document))
			}
		}).catch(error => {
			dispatch(failureClient(CREATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function updateQuestionMethod(id, updated) {
	const { document, ...method_data } = updated
	return dispatch => {
		dispatch(requestClient(UPDATE_QM));
		return client.service('question-methods').patch(id, method_data)
		.then(response => {
			dispatch(successClient(UPDATE_QM, response));
			if (document) {
				dispatch(uploadQuestionMethodDocument(id, document))
			}
		}).catch(error => {
			dispatch(failureClient(UPDATE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethod(id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM));
		return client.service('question-methods').remove(id)
		.then(response => {
			dispatch(successClient(DELETE_QM, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function uploadQuestionMethodDocument(question_method_id, file) {
	return dispatch => {
		dispatch(requestClient(UPLOAD_QM_DOCUMENT));
		const fData = new FormData();
		fData.append('filename', 'x');
		fData.append('file', file);
		fData.append('question_method_id', question_method_id);
		return client.service('noxmon-question-method-documents').create(fData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			dispatch(successClient(UPLOAD_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(UPLOAD_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function getQuestionMethodDocument(question_method_id) {
	return dispatch => {
		dispatch(requestClient(GET_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').get(question_method_id)
		.then(response => {
			dispatch(successClient(GET_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(GET_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}

export function deleteQuestionMethodDocument(document_id) {
	return dispatch => {
		dispatch(requestClient(DELETE_QM_DOCUMENT));
		return client.service('noxmon-question-method-documents').remove(document_id)
		.then(response => {
			console.log(response)
			dispatch(successClient(DELETE_QM_DOCUMENT, response));
		}).catch(error => {
			dispatch(failureClient(DELETE_QM_DOCUMENT, error));
			console.log("Error: ", error);
			return null;
		});
	}
}